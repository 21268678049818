export default [
  {
    industry: "Transport, postal and warehousing",
    name: "Air and space transport"
  },
  {
    industry: "Transport, postal and warehousing",
    name: "Airport operations and other air transport support services"
  },
  {
    industry: "Transport, postal and warehousing",
    name: "Courier pick-up and delivery services"
  },
  {
    industry: "Transport, postal and warehousing",
    name: "Customs agency services"
  },
  {
    industry: "Transport, postal and warehousing",
    name: "Freight forwarding services"
  },
  {
    industry: "Transport, postal and warehousing",
    name: "Grain storage services"
  },
  {
    industry: "Transport, postal and warehousing",
    name: "Interurban and rural bus transport"
  },
  {
    industry: "Transport, postal and warehousing",
    name: "Other transport nec"
  },
  {
    industry: "Transport, postal and warehousing",
    name: "Other transport support services nec"
  },
  {
    industry: "Transport, postal and warehousing",
    name: "Other warehousing and storage services"
  },
  {
    industry: "Transport, postal and warehousing",
    name: "Other water transport support services"
  },
  {
    industry: "Transport, postal and warehousing",
    name: "Pipeline transport"
  },
  {
    industry: "Transport, postal and warehousing",
    name: "Port and water transport terminal operations"
  },
  {
    industry: "Transport, postal and warehousing",
    name: "Postal services"
  },
  {
    industry: "Transport, postal and warehousing",
    name: "Rail freight transport"
  },
  {
    industry: "Transport, postal and warehousing",
    name: "Rail passenger transport"
  },
  {
    industry: "Transport, postal and warehousing",
    name: "Road freight transport"
  },
  {
    industry: "Transport, postal and warehousing",
    name: "Scenic and sightseeing transport"
  },
  {
    industry: "Transport, postal and warehousing",
    name: "Stevedoring services"
  },
  {
    industry: "Transport, postal and warehousing",
    name: "Taxi and other road transport"
  },
  {
    industry: "Transport, postal and warehousing",
    name: "Urban bus transport (including tramway)"
  },
  {
    industry: "Transport, postal and warehousing",
    name: "Water freight transport"
  },
  {
    industry: "Transport, postal and warehousing",
    name: "Water passenger transport"
  },
]
