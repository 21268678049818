export default [
  {
    industry: "Information media and telecommunications",
    name: "Book publishing"
  },
  {
    industry: "Information media and telecommunications",
    name: "Cable and other subscription broadcasting"
  },
  {
    industry: "Information media and telecommunications",
    name: "Data processing and web hosting services"
  },
  {
    industry: "Information media and telecommunications",
    name: "Directory and mailing list publishing"
  },
  {
    industry: "Information media and telecommunications",
    name: "Electronic information storage services"
  },
  {
    industry: "Information media and telecommunications",
    name: "Free-to-air television broadcasting"
  },
  {
    industry: "Information media and telecommunications",
    name: "Internet publishing and broadcasting"
  },
  {
    industry: "Information media and telecommunications",
    name: "Internet service providers and web search portals"
  },
  {
    industry: "Information media and telecommunications",
    name: "Libraries and archives"
  },
  {
    industry: "Information media and telecommunications",
    name: "Magazine and other periodical publishing"
  },
  {
    industry: "Information media and telecommunications",
    name: "Motion picture and video distribution"
  },
  {
    industry: "Information media and telecommunications",
    name: "Motion picture and video production"
  },
  {
    industry: "Information media and telecommunications",
    name: "Motion picture exhibition"
  },
  {
    industry: "Information media and telecommunications",
    name: "Music and other sound recording activities"
  },
  {
    industry: "Information media and telecommunications",
    name: "Music publishing"
  },
  {
    industry: "Information media and telecommunications",
    name: "Newspaper publishing"
  },
  {
    industry: "Information media and telecommunications",
    name: "Other information services"
  },
  {
    industry: "Information media and telecommunications",
    name: "Other publishing (except software, music and internet)"
  },
  {
    industry: "Information media and telecommunications",
    name: "Other telecommunications network operations"
  },
  {
    industry: "Information media and telecommunications",
    name: "Other telecommunications services"
  },
  {
    industry: "Information media and telecommunications",
    name: "Post-production services and other motion picture and video activities"
  },
  {
    industry: "Information media and telecommunications",
    name: "Radio broadcasting"
  },
  {
    industry: "Information media and telecommunications",
    name: "Software publishing"
  },
  {
    industry: "Information media and telecommunications",
    name: "Wired telecommunications network operations"
  },
]
