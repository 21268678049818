export default [
  {
    "industry": "Mining",
    name: "Bauxite mining"
  },
  {
    "industry": "Mining",
    name: "Coal mining"
  },
  {
    "industry": "Mining",
    name: "Copper ore mining"
  },
  {
    "industry": "Mining",
    name: "Gold ore mining"
  },
  {
    "industry": "Mining",
    name: "Gravel and sand quarrying"
  },
  {
    "industry": "Mining",
    name: "Iron ore mining"
  },
  {
    "industry": "Mining",
    name: "Mineral exploration"
  },
  {
    "industry": "Mining",
    name: "Mineral sand mining"
  },
  {
    "industry": "Mining",
    name: "Nickel ore mining"
  },
  {
    "industry": "Mining",
    name: "Oil and gas extraction"
  },
  {
    "industry": "Mining",
    name: "Other construction material mining"
  },
  {
    "industry": "Mining",
    name: "Other metal ore mining"
  },
  {
    "industry": "Mining",
    name: "Other mining support services"
  },
  {
    "industry": "Mining",
    name: "Other non-metallic mineral mining and quarrying"
  },
  {
    "industry": "Mining",
    name: "Petroleum exploration"
  },
  {
    "industry": "Mining",
    name: "Silver-lead-zinc ore mining"
  },
];
