export default [
  {
    industry: "Rental, hiring and real estate services",
    name: "Farm animals and bloodstock leasing"
  },
  {
    industry: "Rental, hiring and real estate services",
    name: "Heavy machinery and scaffolding rental and hiring"
  },
  {
    industry: "Rental, hiring and real estate services",
    name: "Non-financial intangible assets (except copyrights) leasing"
  },
  {
    industry: "Rental, hiring and real estate services",
    name: "Non-residential property operators"
  },
  {
    industry: "Rental, hiring and real estate services",
    name: "Other goods and equipment rental and hiring nec"
  },
  {
    industry: "Rental, hiring and real estate services",
    name: "Other motor vehicle and transport equipment rental and hiring"
  },
  {
    industry: "Rental, hiring and real estate services",
    name: "Passenger car rental and hiring"
  },
  {
    industry: "Rental, hiring and real estate services",
    name: "Real estate services"
  },
  {
    industry: "Rental, hiring and real estate services",
    name: "Residential property operators"
  },
  {
    industry: "Rental, hiring and real estate services",
    name: "Video and other electronic media rental and hiring"
  },
]
