export default [
  {
    industry: "Construction",
    name: "Air conditioning and heating services"
  },
  {
    industry: "Construction",
    name: "Bricklaying services"
  },
  {
    industry: "Construction",
    name: "Carpentry services"
  },
  {
    industry: "Construction",
    name: "Concreting services"
  },
  {
    industry: "Construction",
    name: "Electrical services"
  },
  {
    industry: "Construction",
    name: "Fire and security alarm installation services"
  },
  {
    industry: "Construction",
    name: "Glazing services"
  },
  {
    industry: "Construction",
    name: "Hire of construction machinery with operator"
  },
  {
    industry: "Construction",
    name: "House construction"
  },
  {
    industry: "Construction",
    name: "Land development and subdivisions"
  },
  {
    industry: "Construction",
    name: "Landscape construction services"
  },
  {
    industry: "Construction",
    name: "Non-residential building construction"
  },
  {
    industry: "Construction",
    name: "Other building installation services"
  },
  {
    industry: "Construction",
    name: "Other construction services nec"
  },
  {
    industry: "Construction",
    name: "Other heavy and civil engineering construction"
  },
  {
    industry: "Construction",
    name: "Other residential building construction"
  },
  {
    industry: "Construction",
    name: "Painting and decorating services"
  },
  {
    industry: "Construction",
    name: "Plastering and ceiling services"
  },
  {
    industry: "Construction",
    name: "Plumbing services"
  },
  {
    industry: "Construction",
    name: "Road and bridge construction"
  },
  {
    industry: "Construction",
    name: "Roofing services"
  },
  {
    industry: "Construction",
    name: "Site preparation services"
  },
  {
    industry: "Construction",
    name: "Structural steel erection services"
  },
  {
    industry: "Construction",
    name: "Tiling and carpeting services"
  },
]
