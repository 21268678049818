export default [
  {
    industry: "Professional, scientific and technical services",
    name: "Accounting services"
  },
  {
    industry: "Professional, scientific and technical services",
    name: "Advertising services"
  },
  {
    industry: "Professional, scientific and technical services",
    name: "Architectural services"
  },
  {
    industry: "Professional, scientific and technical services",
    name: "Computer system design and related services"
  },
  {
    industry: "Professional, scientific and technical services",
    name: "Corporate head office management services"
  },
  {
    industry: "Professional, scientific and technical services",
    name: "Engineering design and engineering consulting services"
  },
  {
    industry: "Professional, scientific and technical services",
    name: "Legal services"
  },
  {
    industry: "Professional, scientific and technical services",
    name: "Management advice and related consulting services"
  },
  {
    industry: "Professional, scientific and technical services",
    name: "Market research and statistical services"
  },
  {
    industry: "Professional, scientific and technical services",
    name: "Other professional, scientific and technical services nec"
  },
  {
    industry: "Professional, scientific and technical services",
    name: "Other specialised design services"
  },
  {
    industry: "Professional, scientific and technical services",
    name: "Professional photographic services"
  },
  {
    industry: "Professional, scientific and technical services",
    name: "Scientific research services"
  },
  {
    industry: "Professional, scientific and technical services",
    name: "Scientific testing and analysis services"
  },
  {
    industry: "Professional, scientific and technical services",
    name: "Surveying and mapping services"
  },
  {
    industry: "Professional, scientific and technical services",
    name: "Veterinary services"
  },
]
