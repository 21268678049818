<template>
  <div>
    <div class="flex justify-end mt-2 mb-8">
      Set same contact details for all communication
      <vs-switch
        v-model="commonContact"
        class="ml-2"
      />
    </div>
    <vs-row>
      <vs-col vs-w="4" class="textBlack">Communication type</vs-col>
      <vs-col vs-w="4" class="textBlack">Email address</vs-col>
      <vs-col vs-w="4" class="textBlack">Phone number</vs-col>
    </vs-row>
    <hr class="line-hr-thin mt-2 mb-2">
    <vs-row v-if="commonContact" class="mt-8">
      <vs-col vs-w="4">
        <p>All communication</p>
      </vs-col>
      <vs-col vs-w="4">
        <vs-input
          name="Email"
          v-model="email"
          class="w-full"
          data-vv-as="Email address"
          v-validate="'required|email'"
          @keyup="handleEmail"
          @change="handleEmail"
        />
        <span
          v-show="errors.has(`Email`)"
          class="text-danger text-sm"
        >{{ errors.first(`Email`) }}</span
        >
      </vs-col>
      <vs-col vs-w="4">
        <vs-input
          name="phonenumber"
          v-model="phoneNumber"
          v-mask="[checkPhoneNumber]"
          class="w-full"
          data-vv-as="Phone number"
          v-validate="'required|phoneFormat'"
          @keyup="handlePhoneNumber"
          @change="handlePhoneNumber"
        />
        <span
          v-show="errors.has(`phonenumber`)"
          class="text-danger text-sm"
        >{{ errors.first(`phonenumber`) }}</span
        >
      </vs-col>
    </vs-row>
    <vs-row
      v-for="(contact, index) in payload"
      :key="index"
      class="mt-8"
      v-else
    >
      <vs-col vs-w="4">
        <p>{{contact.name}}
          <vs-tooltip
              :text="`${tooltip[index]}`"
              position="top"
              class="icon-tooltip"
            >
              <img class="icon-control" :src="toolicon" alt="tooltip" />
            </vs-tooltip>
        </p>
      </vs-col>
      <vs-col vs-w="4">
        <vs-input
          :name="`Email[${index}]`"
          v-model="contact.email"
          class="w-full"
          data-vv-as="Email address"
          v-validate="contact.name == 'Technical support' ? 'email' : 'required|email'"
          @keyup="handleChange(index, 'email')"
          @change="handleChange(index, 'email')"
        />
        <span
          v-show="errors.has(`Email[${index}]`)"
          class="text-danger text-sm"
        >{{ errors.first(`Email[${index}]`) }}</span
        >
      </vs-col>
      <vs-col vs-w="4">
        <vs-input
          :name="`Phone[${index}]`"
          v-model="contact.phoneNumber"
          v-mask="numberMask[index].mask"
          class="w-full"
          data-vv-as="Phone number"
          v-validate="contact.name == 'Technical support' ? 'phoneFormat' : 'required|phoneFormat'"
          @keyup="handleChange(index, 'phoneNumber')"
          @change="handleChange(index, 'phoneNumber')"
          v-on:input="checkValidPhoneNumber(index)"
        />
        <span
          v-show="errors.has(`Phone[${index}]`)"
          class="text-danger text-sm"
        >{{ errors.first(`Phone[${index}]`) }}</span
        >
      </vs-col>
    </vs-row>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";
const toolicon = require("@/assets/images/tooltip_dark.svg");

export default {
  name: "contactDetails",
  directives: { mask },
  props: {
    isContactCommonForAll: { type: Boolean },
    contactDetails: { type: Array },
    tooltip: { type: Array },
    isComplete: { type: Object },
    applicationStatus: {type: String},
  },
  data() {
    return {
      commonContact: {},
      payload: {},
      originalValues: {},
      hasBeenCompleted: {},
      email: "",
      phoneNumber: "",
      toolicon: toolicon,
      numberMask: [
        {
          mask: "#### ### ###"
        },
        {
          mask: "#### ### ###"
        },
        {
          mask: "#### ### ###"
        },
      ]
    };
  },
  created() {
    this.commonContact = this.isContactCommonForAll;
    this.payload = this.contactDetails;
    this.originalValues = JSON.parse(JSON.stringify(this.contactDetails));
    this.hasBeenCompleted = this.isComplete;
    if(this.commonContact){
      this.email = this.payload[0].email;
      this.phoneNumber = this.payload[0].phoneNumber;
    }
    this.checkIfCardIsComplete();
  },
  methods: {
    ...mapActions("onboardMerchant", ["checkMerchantEmailValidity"]),

    checkIfCardIsComplete() {
      let statuses = [];
      let status = true;
      if(this.commonContact){
        statuses[0] = this.phoneNumber.length > 0 &&
          !this.errors.has(`phonenumber`) &&
          this.email.length > 0 &&
          !this.errors.has(`Email`);
      }else{
        for (let index = 0; index < this.payload.length; index++) {
          let { phoneNumber, email} = this.payload[index]
          statuses[index] =
            (
              "Technical support" == this.payload[index].name ||
              this.payload[index].phoneNumber && this.payload[index].phoneNumber.length > 0
            ) &&
            !this.errors.has(`Phone[${index}]`) &&
            (
              "Technical support" == this.payload[index].name ||
              this.payload[index].email && this.payload[index].email.length > 0
            ) &&
            !this.errors.has(`Email[${index}]`);
        }
      }
      for (let index = 0; index < statuses.length; index++) {
        if (!statuses[index]) {
          status = false;
          break;
        }
      }

      this.hasBeenCompleted.contactDetailsStatus = status;
    },

    validateForm() {
      this.checkIfCardIsComplete();
      return this.$validator.validateAll();
    },

    handleEmail(){
      for (let index = 0; index < this.payload.length; index++) {
        this.payload[index].email = this.email
        this.handleChange(index,'email')
      }
      this.checkIfCardIsComplete();
    },

    handlePhoneNumber(){
      for (let index = 0; index < this.payload.length; index++) {
        this.payload[index].phoneNumber = this.phoneNumber
        this.handleChange(index,'phoneNumber')
      }
      this.checkIfCardIsComplete();
    },

    handleToggle(){
      this.$emit("changeToggle", this.commonContact);
      for (let index = 0; index < this.payload.length; index++) {
        this.payload[index].email = "";
        this.payload[index].phoneNumber = "";
      }
      this.email = "";
      this.phoneNumber = "";
      this.$validator.pause()
      this.$nextTick(() => {
        this.$validator.errors.clear()
        this.$validator.fields.items.forEach(field => field.reset())
        this.$validator.fields.items.forEach(field => this.errors.remove(field))
        this.$validator.resume()
      })
    },

    checkValidPhoneNumber(value){
      let checkNumber = this.contactDetails[value].phoneNumber;
      this.numberMask[value].mask = this.checkDynamicPhoneNumber(checkNumber);
    },

    checkDynamicPhoneNumber(value){
      let mask = '#### ### ###';
      if(value && value.length > 0){
        let numLength = value.length;
        let value1 = '';
        let value2 = '';
        if(numLength >= 2){
          value1 = value.substring(0,2);
          if(value1 === '13'){
            value2 = value.split(" ").join("").substring(0,4);
          }
        }
        if(value1 === '02' || value1 === '03' || value1 === '07' || value1 === '08'){
          mask = "## #### ####"
        }else{
          mask = "#### ### ###"
        }

        if(value1 === '13'){
          if(parseInt(value2) == 1300){
            mask = "#### ### ###"
          }else{
            mask = "## ## ##"
          }
        }
      }
      return mask;
    },
    handleChange(index,flag){
      if(this.applicationStatus === 'Info Requested'){
        let changedlabel = `contactDetails[${index}]${flag}`
        const data = {
          label: changedlabel,
          oldValue: this.originalValues[index][flag],
          newValue: this.payload[index][flag]
        }
        this.$emit("resubmit", data);
      }
      this.checkIfCardIsComplete();
    }
  },
  watch: {
    isFormDirty(val) {
      if (val) {
        this.$emit("checkDirty", this.isFormDirty);
      }
    },
    commonContact(){
      if(this.isContactCommonForAll !== this.commonContact){
        this.handleToggle();
      }
    }
  },
  computed: {
    checkPhoneNumber(){
      let mask = '#### ### ###';
      if(this.phoneNumber && this.phoneNumber.length > 0){
        let numLength = this.phoneNumber.length;
        let value1 = '';
        let value2 = '';
        if(numLength >= 2){
          value1 = this.phoneNumber.substring(0,2);
          if(value1 === '13'){
            value2 = this.phoneNumber.split(" ").join("").substring(0,4);
          }
        }
        if(value1 === '02' || value1 === '03' || value1 === '07' || value1 === '08'){
          mask = "## #### ####"
        }else{
          mask = "#### ### ###"
        }

        if(value1 === '13'){
          if(parseInt(value2) == 1300){
            mask = "#### ### ###"
          }else{
            mask = "## ## ##"
          }
        }
      }
      return mask;
    }
  }
};
</script>
