<template>
  <div>
    <template v-for="(signature, key) in authorisedSignatures">
      <div class="flex mb-8" :key="key">
        <div class="w-full" :id="'authsign' + key">
          <div :key="key">
            <h5 class="text-base font-normal">
              Authorised signatory {{ parseInt(key) + 1 }}
            </h5>
            <hr class="line-hr mb-8 mt-2" />
            <div>
              <div class="flex mt-4 flex-wrap">
                <div
                  class="input-grp"
                  v-if="
                    signature.hasOwnProperty('isSameAsAuthorisedSignature') &&
                    signature.isSameAsAuthorisedSignature
                  "
                >
                  <label class="vs-input--label w-full p-0">
                    First Name
                    <span class="mid-blue">*</span>
                  </label>
                  <vs-input
                    :name="`First Name[${key}]`"
                    v-model="signature.firstName"
                    class="w-full"
                    data-vv-as="First name"
                    @keyup="checkIfCardIsComplete"
                    :disabled="
                      signature.hasOwnProperty('isSameAsAuthorisedSignature') &&
                      signature.isSameAsAuthorisedSignature
                    "
                  />
                </div>

                <div v-else class="input-grp">
                  <label
                    class="vs-input--label w-full p-0"
                    :class="
                      errors.has(`First Name[${key}]`) ? 'text-danger' : ''
                    "
                  >
                    First Name
                    <span class="mid-blue">*</span>
                  </label>
                  <vs-input
                    :name="`First Name[${key}]`"
                    v-model="signature.firstName"
                    class="w-full"
                    v-validate="'required'"
                    data-vv-as="First name"
                    @keyup="checkIfCardIsComplete"
                    :disabled="
                      signature.hasOwnProperty('isSameAsAuthorisedSignature') &&
                      signature.isSameAsAuthorisedSignature
                    "
                  />
                  <span
                    v-show="errors.has(`First Name[${key}]`)"
                    class="text-danger text-sm"
                  >
                    {{ errors.first(`First Name[${key}]`) }}
                  </span>
                </div>

                <div class="input-grp">
                  <label class="vs-input--label w-full p-0">Middle Name</label>
                  <vs-input
                    :name="`Middle Name[${key}]`"
                    v-model="signature.middleName"
                    data-vv-as="Middle name"
                    class="w-full"
                    @keyup="checkIfCardIsComplete"
                    :disabled="
                      signature.hasOwnProperty('isSameAsAuthorisedSignature') &&
                      signature.isSameAsAuthorisedSignature
                    "
                  />
                  <span
                    v-show="errors.has(`Middle Name[${key}]`)"
                    class="text-danger text-sm"
                  >
                    {{ errors.first(`Middle Name[${key}]`) }}
                  </span>
                </div>
              </div>

              <div
                class="mt-8 input-grp"
                v-if="
                  signature.hasOwnProperty('isSameAsAuthorisedSignature') &&
                  signature.isSameAsAuthorisedSignature
                "
              >
                <label class="vs-input--label w-full p-0">
                  Last Name
                  <span class="mid-blue">*</span>
                </label>
                <vs-input
                  :name="`Last Name[${key}]`"
                  v-model="signature.lastName"
                  data-vv-as="Last name"
                  class="w-full surcharge-top"
                  @keyup="checkIfCardIsComplete"
                  :disabled="
                    signature.hasOwnProperty('isSameAsAuthorisedSignature') &&
                    signature.isSameAsAuthorisedSignature
                  "
                />
              </div>

              <div v-else class="mt-8 input-grp">
                <label
                  class="vs-input--label w-full p-0"
                  :class="errors.has(`Last Name[${key}]`) ? 'text-danger' : ''"
                >
                  Last Name
                  <span class="mid-blue">*</span>
                </label>
                <vs-input
                  :name="`Last Name[${key}]`"
                  v-model="signature.lastName"
                  data-vv-as="Last name"
                  v-validate="'required'"
                  class="w-full surcharge-top"
                  @keyup="checkIfCardIsComplete"
                  :disabled="
                    signature.hasOwnProperty('isSameAsAuthorisedSignature') &&
                    signature.isSameAsAuthorisedSignature
                  "
                />
                <span
                  v-show="errors.has(`Last Name[${key}]`)"
                  class="text-danger text-sm"
                >
                  {{ errors.first(`Last Name[${key}]`) }}
                </span>
              </div>

              <div class="flex mt-8 flex-wrap">
                <div
                  class="input-grp"
                  v-if="
                    signature.hasOwnProperty('isSameAsAuthorisedSignature') &&
                    signature.isSameAsAuthorisedSignature
                  "
                >
                  <label class="vs-input--label w-full p-0">
                    Mobile Phone Number
                    <span class="mid-blue">*</span>
                  </label>
                  <vs-input
                    :name="`Mobile[${key}]`"
                    v-model="signature.mobileNumber"
                    v-mask="'#### ### ###'"
                    data-vv-as="Mobile phone number"
                    class="w-full surcharge-top"
                    @keyup="checkIfCardIsComplete"
                    :disabled="
                      signature.hasOwnProperty('isSameAsAuthorisedSignature') &&
                      signature.isSameAsAuthorisedSignature
                    "
                  />
                </div>

                <div class="input-grp" v-else>
                  <label
                    class="vs-input--label w-full p-0"
                    :class="errors.has(`Mobile[${key}]`) ? 'text-danger' : ''"
                  >
                    Mobile Phone Number
                    <span class="mid-blue">*</span>
                  </label>
                  <vs-input
                    :name="`Mobile[${key}]`"
                    v-model="signature.mobileNumber"
                    v-mask="'#### ### ###'"
                    v-validate="{
                      required: true,
                      regex: /^04\(?\d{2}\)?[\s.-]\d{3}[\s.-]\d{3}$/,
                    }"
                    class="w-full surcharge-top"
                    data-vv-as="Mobile phone number"
                    @keyup="checkIfCardIsComplete"
                    :disabled="
                      signature.hasOwnProperty('isSameAsAuthorisedSignature') &&
                      signature.isSameAsAuthorisedSignature
                    "
                  />
                  <span
                    v-show="errors.has(`Mobile[${key}]`)"
                    class="text-danger text-sm"
                    >{{ errors.first(`Mobile[${key}]`) }}</span
                  >
                </div>

                <div
                  class="input-grp"
                  v-if="
                    signature.hasOwnProperty('isSameAsAuthorisedSignature') &&
                    signature.isSameAsAuthorisedSignature
                  "
                >
                  <label class="vs-input--label w-full p-0">
                    Email Address
                    <span class="mid-blue">*</span>
                  </label>
                  <vs-input
                    :name="`Email[${key}]`"
                    v-model="signature.email"
                    data-vv-as="Email address"
                    class="w-full"
                    @keyup="checkIfCardIsComplete"
                    :disabled="
                      signature.hasOwnProperty('isSameAsAuthorisedSignature') &&
                      signature.isSameAsAuthorisedSignature
                    "
                  />
                </div>

                <div v-else class="input-grp">
                  <label
                    class="vs-input--label w-full p-0"
                    :class="errors.has(`Email[${key}]`) ? 'text-danger' : ''"
                  >
                    Email Address
                    <span class="mid-blue">*</span>
                  </label>
                  <vs-input
                    :name="`Email[${key}]`"
                    v-model="signature.email"
                    v-validate="'required|email'"
                    data-vv-as="Email address"
                    class="w-full"
                    @keyup="checkIfCardIsComplete"
                    :disabled="
                      signature.hasOwnProperty('isSameAsAuthorisedSignature') &&
                      signature.isSameAsAuthorisedSignature
                    "
                  />
                  <span
                    v-show="errors.has(`Email[${key}]`)"
                    class="text-danger text-sm"
                    >{{ errors.first(`Email[${key}]`) }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <!-- <vs-icon
            icon="delete_outline"
            size="small"
            class="float-right"
            @click="deleteAuthorisedSignature(key, signature)"
            v-show="authorisedSignatures.length>1"
          ></vs-icon> -->
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            @click="deleteAuthorisedSignature(key, signature)"
            v-show="authorisedSignatures.length > 1"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.66675 4.99992C1.66675 4.53968 2.03984 4.16658 2.50008 4.16658H17.5001C17.9603 4.16658 18.3334 4.53968 18.3334 4.99992C18.3334 5.46016 17.9603 5.83325 17.5001 5.83325H2.50008C2.03984 5.83325 1.66675 5.46016 1.66675 4.99992Z"
              fill="#757575"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.33342 2.49992C8.1124 2.49992 7.90044 2.58772 7.74416 2.744C7.58788 2.90028 7.50008 3.11224 7.50008 3.33325V4.16658H12.5001V3.33325C12.5001 3.11224 12.4123 2.90028 12.256 2.744C12.0997 2.58772 11.8878 2.49992 11.6667 2.49992H8.33342ZM14.1667 4.16658V3.33325C14.1667 2.67021 13.9034 2.03433 13.4345 1.56548C12.9657 1.09664 12.3298 0.833252 11.6667 0.833252H8.33342C7.67037 0.833252 7.03449 1.09664 6.56565 1.56548C6.09681 2.03433 5.83342 2.67021 5.83342 3.33325V4.16658H4.16675C3.70651 4.16658 3.33341 4.53968 3.33341 4.99992V16.6666C3.33341 17.3296 3.59681 17.9655 4.06565 18.4344C4.53449 18.9032 5.17037 19.1666 5.83342 19.1666H14.1667C14.8298 19.1666 15.4657 18.9032 15.9345 18.4344C16.4034 17.9655 16.6667 17.3296 16.6667 16.6666V4.99992C16.6667 4.53968 16.2937 4.16658 15.8334 4.16658H14.1667ZM5.00008 5.83325V16.6666C5.00008 16.8876 5.08788 17.0996 5.24416 17.2558C5.40044 17.4121 5.6124 17.4999 5.83342 17.4999H14.1667C14.3878 17.4999 14.5997 17.4121 14.756 17.2558C14.9123 17.0996 15.0001 16.8876 15.0001 16.6666V5.83325H5.00008Z"
              fill="#757575"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.33342 8.33325C8.79365 8.33325 9.16675 8.70635 9.16675 9.16658V14.1666C9.16675 14.6268 8.79365 14.9999 8.33342 14.9999C7.87318 14.9999 7.50008 14.6268 7.50008 14.1666V9.16658C7.50008 8.70635 7.87318 8.33325 8.33342 8.33325Z"
              fill="#757575"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.6667 8.33325C12.127 8.33325 12.5001 8.70635 12.5001 9.16658V14.1666C12.5001 14.6268 12.127 14.9999 11.6667 14.9999C11.2065 14.9999 10.8334 14.6268 10.8334 14.1666V9.16658C10.8334 8.70635 11.2065 8.33325 11.6667 8.33325Z"
              fill="#757575"
            />
          </svg>
        </div>
      </div>
    </template>
    <a
      class="text-base mid-blue flex items-center"
      @click="addAuthorisedSignature"
      ><span class="pr-3">
        <plus-icon size="1.5x" class="button-icon mid-blue"></plus-icon>
      </span>
      <span class="text-base mid-blue underline">Add Authorised Signatory</span>
    </a>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import { EventBus } from "../../../../event-bus";
import { PlusIcon } from "vue-feather-icons";

export default {
  components: {
    PlusIcon,
  },
  name: "AuthorisedSignature",
  directives: { mask },
  props: {
    authorisedSignatures: { type: Array },
    isComplete: { type: Object },
  },
  data() {
    return {
      payload: [],
      hasBeenCompleted: {},
    };
  },
  created() {
    this.payload = this.authorisedSignatures; //.filter(signature => !signature.isSameAsAuthorisedSignature);
    this.hasBeenCompleted = this.isComplete;

    EventBus.$on("addBeneficialOwnerAsAuthorisedSignature", async (owner) => {
      if (
        [0, 1].includes(this.authorisedSignatures.length) &&
        this.authorisedSignatures.every((signature) => {
          return (
            !signature.firstName &&
            !signature.middleName &&
            !signature.lastName &&
            !signature.mobileNumber &&
            !signature.email
          );
        })
      ) {
        this.authorisedSignatures.splice(0, 1);
        this.authorisedSignatures.push(owner);
      } else {
        this.authorisedSignatures.push(owner);
      }
      await this.$validator.validate();
      this.checkIfCardIsComplete();
    });

    EventBus.$on("removeBeneficialOwnerFromAuthorisedSignature", (owner) => {
      const indexToRemove = this.authorisedSignatures.findIndex(
        (row) =>
          row.email === owner.email && row.mobileNumber === owner.mobileNumber
      );
      if (indexToRemove !== -1)
        this.authorisedSignatures.splice(indexToRemove, 1);
      if (this.authorisedSignatures && this.authorisedSignatures.length === 0)
        this.authorisedSignatures.push({
          firstName: "",
          lastName: "",
          middleName: "",
          email: "",
          mobileNumber: "",
        });
      this.checkIfCardIsComplete();
    });
  },

  methods: {
    addAuthorisedSignature() {
      this.authorisedSignatures.push({
        firstName: "",
        middleName: "",
        lastName: "",
        mobileNumber: "",
        email: "",
      });
      this.checkIfCardIsComplete();
      this.$emit("handleCardHeight");
    },

    changeMessage(data, removeString, addString) {
      return data ? data.replace(removeString, addString) : "";
    },

    deleteAuthorisedSignature(key, data) {
      this.authorisedSignatures.splice(key, 1);
      this.checkIfCardIsComplete();
      this.$emit("handleCardHeight");
      if (
        data.hasOwnProperty("isSameAsAuthorisedSignature") &&
        data.isSameAsAuthorisedSignature
      )
        EventBus.$emit("unCheckInBusinessOwnerForAuthorisedSignature", data);
    },

    checkIfCardIsComplete(slug = "") {
  
      let statuses = [];
      let status = true;

      for (let index = 0; index < this.authorisedSignatures.length; index++) {
        let {firstName, lastName, mobileNumber, email} = this.authorisedSignatures[index]
          let isUnique = true
          let isMobileUnique = true
          let isEmailUnique = true
          for (let ownerIndex = 0; ownerIndex < this.authorisedSignatures.length; ownerIndex++) {
            if(ownerIndex != index && firstName && lastName && slug != "updated"){
               if(`${firstName}${lastName}` == 
              `${this.authorisedSignatures[ownerIndex].firstName}${this.authorisedSignatures[ownerIndex].lastName}`){
                isUnique = false
                  this.errors.add({
                    field: `Last Name[${index}]`,
                    msg: "This name has been already used."
                  })
                  
                  this.errors.add({
                    field: `First Name[${index}]`,
                    msg: "This name has been already used."
                  })
              }
              else if(isUnique){
                this.errors.remove(`Last Name[${index}]`)
                this.errors.remove(`First Name[${index}]`)
              }
                
            }
            //unique email
            if(ownerIndex != index && email && slug != "updated" && 
                ( !this.errors.has(`Email[${index}]`) || 
                  this.errors.first(`Email[${index}]`) == "This email has been already used."
                )
              ){
     

              if(`${email}` == 
              `${this.authorisedSignatures[ownerIndex].email}`){
                  isEmailUnique = false
                  this.errors.add({
                    field: `Email[${index}]`,
                    msg: "This email has been already used."
                  })
              }
              else if(isEmailUnique){
                this.errors.remove(`Email[${index}]`)
              }
            }
            //unique mobile number
            if(ownerIndex != index && mobileNumber && slug != "updated" && 
                ( !this.errors.has(`Mobile[${index}]`) || 
                  this.errors.first(`Mobile[${index}]`) == "This number has been already used."
                )){

              if(`${mobileNumber}` == 
              `${this.authorisedSignatures[ownerIndex].mobileNumber}`){
                  isMobileUnique = false
                  this.errors.add({
                    field: `Mobile[${index}]`,
                    msg: "This number has been already used."
                  })
              }
              else if(isMobileUnique){
                this.errors.remove(`Mobile[${index}]`)
              }
            }

          }

        // if (this.authorisedSignatures[index].isSameAsAuthorisedSignature) {
        //   statuses[index] = true;
        // } else {
        //   statuses[index] =
        //     this.authorisedSignatures[index].firstName.length > 0 &&
        //     this.authorisedSignatures[index].lastName.length > 0 &&
        //     this.authorisedSignatures[index].mobileNumber.length > 0 &&
        //     !this.errors.has(`Mobile[${index}]`) &&
        //     this.authorisedSignatures[index].email.length > 0 &&
        //     !this.errors.has(`Email[${index}]`);
        // }
      }

      for (let index = 0; index < statuses.length; index++) {
        if (!statuses[index]) {
          status = false;
          break;
        }
      }

      this.hasBeenCompleted.authorisedSignaturesStatus = status;
    },

    validateForm() {
      return this.$validator.validateAll();
    },
  },
  watch: {
    isFormDirty(val) {
      if (val) {
        this.$emit("checkDirty", this.isFormDirty);
      }
    },
  },

  updated() {
    this.checkIfCardIsComplete("updated");
  },
};
</script>
