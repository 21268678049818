export default [
  {
    industry: "Financial and insurance services",
    name: "Auxiliary insurance services"},
  {
    industry: "Financial and insurance services",
    name: "Banking"},
  {
    industry: "Financial and insurance services",
    name: "Building society operation"},
  {
    industry: "Financial and insurance services",
    name: "Central banking"},
  {
    industry: "Financial and insurance services",
    name: "Credit union operation"},
  {
    industry: "Financial and insurance services",
    name: "Financial asset broking services"},
  {
    industry: "Financial and insurance services",
    name: "Financial asset investing"},
  {
    industry: "Financial and insurance services",
    name: "General insurance"},
  {
    industry: "Financial and insurance services",
    name: "Health insurance"},
  {
    industry: "Financial and insurance services",
    name: "Life insurance"},
  {
    industry: "Financial and insurance services",
    name: "Non-depository financing"},
  {
    industry: "Financial and insurance services",
    name: "Other auxiliary finance and investment services"},
  {
    industry: "Financial and insurance services",
    name: "Other depository financial intermediation"},
  {
    industry: "Financial and insurance services",
    name: "Superannuation funds"},
]
