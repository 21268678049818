<template>
  <div>
    <!-- onboarding custom fields -->
    <vs-row v-if="message" class="mt-4 mb-6">
      <vs-col vs-w="12" vs-lg="12" vs-sm="12" vs-xs="12">
        <p> {{ message }}</p>
      </vs-col>
    </vs-row>
    <vs-row v-if="customFieldsArray && customFieldsArray.length > 0">
      <vs-col vs-w="6" vs-lg="6" vs-sm="12" vs-xs="12" class="mb-4 mt-6" v-for="(field, index) in customFieldsArray" :key="index">
        <vs-row>
          <div class="input-grp">
            <label class="vs-input--label w-full p-0" :class="errors.has(`customFields.${field.label}`) ? 'text-danger' : ''">{{ field.label }}
              <vs-tooltip v-if="field.tooltipText" :text="field.tooltipText" position="top" class="icon-tooltip">
                <img class="icon-control" :src="tooltip" alt="tooltip" />
              </vs-tooltip>
            </label>
            <div v-if="field.dataType == 'dropdown'">
              <vs-select
                v-validate="{
                  required: field.isRequired ? true : false,
                }"
                data-vv-scope="customFields"
                class="selectExample w-full"
                v-model="field.value"
                :name="`${field.label}`"
                :placeholder="checkPlaceholder(field.isRequired)"
                @change="handleChange(index)"
              >
                <vs-select-item :key="i" :value="dropdown" :text="dropdown" v-for="(dropdown, i) in field.dropdownOptions"/>
              </vs-select>
              <span class="text-danger text-sm" v-if="errors.has(`customFields.${field.label}`)">{{ errors.first(`customFields.${field.label}`) }}</span>
            </div>

            <div v-else-if="field.dataType === 'currency'">
              <v-money-spinner
                data-vv-scope="customFields"
                :name="`${field.label}`"
                v-model="field.value"
                v-bind="config"
                v-validate="`${field.isRequired ? 'required' : ''}`"
                :placeholder="checkPlaceholder(field.isRequired)"
                class="w-full currency-input"
                @change="handleChange(index)"></v-money-spinner>
              <span class="text-danger text-sm" v-show="errors.has(`customFields.${field.label}`)">{{ errors.first(`customFields.${field.label}`) }}</span>
            </div>

            <div v-else-if="field.dataType !== 'file'">
              <vs-input
                v-if="field.dataType !== `date`"
                v-validate="{ required: field.isRequired ? true : false }"
                data-vv-scope="customFields"
                :type="field.dataType"
                class="w-full"
                size="large"
                :name="`${field.label}`"
                v-model="field.value"
                :placeholder="checkPlaceholder(field.isRequired)"
                @keyup="handleChange(index)"
                @change="handleChange(index)"
                @keypress="(field.dataType == 'number') ? isNumber($event) : ''"
              />
              <div>
                <span class="text-danger text-sm" v-if="errors.has(`customFields.${field.label}`) && field.dataType !== `date`">{{ errors.first(`customFields.${field.label}`) }}</span>
              </div>

              <vs-input
                v-if="field.dataType == `date`"
                v-validate="{ required: field.isRequired ? true : false }"
                data-vv-scope="customFields"
                :type="field.dataType"
                class="w-full"
                size="large"
                :name="`${field.label}`"
                v-model="field.value"
                @change="handleChange(index)"
              />
              <div>
                <span class="text-danger text-sm" v-if="errors.has(`customFields.${field.label}`) && field.dataType == `date`">{{ errors.first(`customFields.${field.label}`) }}</span>
              </div>
            </div>

            <div class="add-info mb-6 lg:mb-8" v-if="field.dataType == 'file'">
              <div class="addFile">
                <S3FileGenerator class="text-sm" v-if="field.value" :document="field.value" :key="field.value"/>
                <span v-if="field.file">{{ field.file.name }}</span>
                <a class="fileAction" v-if="field.file || field.value">
                  <svg class="ml-auto" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" @click="uploadFileClear(`file_${field._id}`, field, index)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.66675 4.99992C1.66675 4.53968 2.03984 4.16658 2.50008 4.16658H17.5001C17.9603 4.16658 18.3334 4.53968 18.3334 4.99992C18.3334 5.46016 17.9603 5.83325 17.5001 5.83325H2.50008C2.03984 5.83325 1.66675 5.46016 1.66675 4.99992Z" fill="#757575"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.33342 2.49992C8.1124 2.49992 7.90044 2.58772 7.74416 2.744C7.58788 2.90028 7.50008 3.11224 7.50008 3.33325V4.16658H12.5001V3.33325C12.5001 3.11224 12.4123 2.90028 12.256 2.744C12.0997 2.58772 11.8878 2.49992 11.6667 2.49992H8.33342ZM14.1667 4.16658V3.33325C14.1667 2.67021 13.9034 2.03433 13.4345 1.56548C12.9657 1.09664 12.3298 0.833252 11.6667 0.833252H8.33342C7.67037 0.833252 7.03449 1.09664 6.56565 1.56548C6.09681 2.03433 5.83342 2.67021 5.83342 3.33325V4.16658H4.16675C3.70651 4.16658 3.33341 4.53968 3.33341 4.99992V16.6666C3.33341 17.3296 3.59681 17.9655 4.06565 18.4344C4.53449 18.9032 5.17037 19.1666 5.83342 19.1666H14.1667C14.8298 19.1666 15.4657 18.9032 15.9345 18.4344C16.4034 17.9655 16.6667 17.3296 16.6667 16.6666V4.99992C16.6667 4.53968 16.2937 4.16658 15.8334 4.16658H14.1667ZM5.00008 5.83325V16.6666C5.00008 16.8876 5.08788 17.0996 5.24416 17.2558C5.40044 17.4121 5.6124 17.4999 5.83342 17.4999H14.1667C14.3878 17.4999 14.5997 17.4121 14.756 17.2558C14.9123 17.0996 15.0001 16.8876 15.0001 16.6666V5.83325H5.00008Z" fill="#757575"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.33342 8.33325C8.79365 8.33325 9.16675 8.70635 9.16675 9.16658V14.1666C9.16675 14.6268 8.79365 14.9999 8.33342 14.9999C7.87318 14.9999 7.50008 14.6268 7.50008 14.1666V9.16658C7.50008 8.70635 7.87318 8.33325 8.33342 8.33325Z" fill="#757575"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6667 8.33325C12.127 8.33325 12.5001 8.70635 12.5001 9.16658V14.1666C12.5001 14.6268 12.127 14.9999 11.6667 14.9999C11.2065 14.9999 10.8334 14.6268 10.8334 14.1666V9.16658C10.8334 8.70635 11.2065 8.33325 11.6667 8.33325Z" fill="#757575"/>
                  </svg>
                </a>
              </div>

              <input
                type="file"
                class="hidden"
                v-validate="{ required: field.isRequired && Object.keys(field.value).length == 0 && !field.file ? true : false, size: maxFileSize * 1024 }"
                :name="field.label"
                accept=".pdf"
                :ref="`file_${field._id}`"
                @change="uploadFileChanged($event, field, index)"/>

              <div>
                <vs-button v-round class="only-border-btn mt-2" size="small" style="width: 126px" @click="uploadFileClick(`file_${field._id}`)">Upload file</vs-button>
              </div>

              <div>
                <span class="text-danger text-sm" v-if="errors.has(`customFields.${field.label}`)">{{ errors.first(`customFields.${field.label}`) }}</span>
              </div>
            </div>
          </div>
        </vs-row>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import { Money } from "v-money";
import { Validator } from "vee-validate";
import S3FileGenerator from "../../components/S3FileGenerator";

const tooltip = require("@/assets/images/tooltip_dark.svg");

export default {
  name: "BusinessProfile",
  components: { Money, S3FileGenerator },
  props: {
    businessProfile: { type: Object },
    isComplete: { type: Object },
    customFieldsArray: { type: Array },
    applicationStatus: { type: String },
    message: { type: String },
  },

  data() {
    return {
      config: {
        spinner: false,
        prefix: "$ ",
        suffix: "",
        precision: 0,
        decimal: '.',
        thousands: ',',
        masked: false,
      },
      hasBeenCompleted: {},
      maxFileSize: process.env.VUE_APP_FILE_MAX_SIZE_MB,
      payload: [],
      tooltip: tooltip,
    };
  },

  created() {
    this.payload = JSON.parse(JSON.stringify(this.customFieldsArray));
    this.hasBeenCompleted = this.isComplete;
    this.checkIfCardIsComplete();
  },

  methods: {
    checkIfCardIsComplete() {
      let statusesOfCffield = [];
      let filteredCFArray = this.customFieldsArray.filter((element) => {
        return (element.isRequired == true && (!element.value || element.value.length === 0));
      });

      if (filteredCFArray.length == 0) {
        this.hasBeenCompleted.businessProfileStatus = true;
      }

      if (filteredCFArray.length > 0) {
        this.hasBeenCompleted.businessProfileStatus = false;
      }

      for (let cfIndex = 0; cfIndex < filteredCFArray.length; cfIndex++) {
        if (filteredCFArray[cfIndex].dataType === "currency") {
          statusesOfCffield[cfIndex] = filteredCFArray[cfIndex].value >= 0;
        } else {
          statusesOfCffield[cfIndex] = filteredCFArray[cfIndex].value.length > 0;
        }

        this.hasBeenCompleted.businessProfileStatus = statusesOfCffield[cfIndex];

        if (!statusesOfCffield[cfIndex]) {
          break;
        }
      }
    },

    validateForm() {
      return this.$validator.validateAll();
    },

    auto_grow(elem) {
      var element = document.getElementById(elem);
      element.style.height = "5px";
      element.style.height = element.scrollHeight + "px";
      this.$emit("profileHeight");
    },

    checkPlaceholder(value) {
      if (!value) {
        return "Optional";
      }
      return;
    },

    handleChange(index) {
      if (this.applicationStatus === 'Info Requested') {
        let changedlabel = `onboardingCustomFields[${index}]${this.customFieldsArray[index].label}`
        const data = {
          label: changedlabel,
          oldValue: this.payload[index].value,
          newValue: this.customFieldsArray[index].value
        }
        this.$emit("resubmit", data);
      }
      this.checkIfCardIsComplete();
    },

    uploadFileClick(ref) {
      this.$refs[ref][0].click();
    },

    uploadFileChanged(input, field, index) {
      if (input.target && input.target.files.length > 0 && input.target.files[0]) {
        const fileReader = new FileReader();
        const self = this;

        field.file = input.target.files[0];
        field.fileName = field.file.name;
        field.fileType = field.file.type;
        field.value = "";

        fileReader.readAsDataURL(field.file);
        fileReader.onload = function (evt) {
          field.fileData = evt.target.result; // set as base64 string
          field.value = field.file.name;
          self.handleChange(index);
          self.$forceUpdate();
        }
      }
    },

    uploadFileClear(ref, field, index) {
      field.value = "";
      this.$refs[ref][0].value = "";

      delete field.file;
      delete field.fileData;

      if (this.errors.has(field.label)) {
        this.errors.remove(field.label);
      }

      this.handleChange(index);
      this.$forceUpdate();
    },

    isNumber($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault();
      }
    },
  },
  watch: {
    //required isFormChanged rather than isFormDirty since dirty is being tracked true even if value is not changed
    isFormChanged(val) {
      if (val) {
        this.$emit("checkDirty", this.isFormChanged);
      }
    },
  },
};
</script>
