export default [
  {
    industry: "Education and training",
    name: "Adult, community and other education nec"
  },
  {
    industry: "Education and training",
    name: "Arts education"
  },
  {
    industry: "Education and training",
    name: "Combined primary and secondary education"
  },
  {
    industry: "Education and training",
    name: "Educational support services"
  },
  {
    industry: "Education and training",
    name: "Higher education"
  },
  {
    industry: "Education and training",
    name: "Preschool education"
  },
  {
    industry: "Education and training",
    name: "Primary education"
  },
  {
    industry: "Education and training",
    name: "Secondary education"
  },
  {
    industry: "Education and training",
    name: "Special school education"
  },
  {
    industry: "Education and training",
    name: "Sports and physical recreation instruction"
  },
  {
    industry: "Education and training",
    name: "Technical and vocational education and training"
  },
]
