<template>
  <div>
    <vs-row class="mb-8" v-if="rawProducts && rawProducts.length > 1">
      <vs-col vs-w="12">
        <div class="flex items-center">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.54657 0.968004C9.82273 0.788497 10.1787 0.788497 10.4549 0.968004L18.7882 6.38467C19.0247 6.53839 19.1674 6.80131 19.1674 7.08337V12.9167C19.1674 13.1988 19.0247 13.4617 18.7882 13.6154L10.4549 19.0321C10.1787 19.2116 9.82273 19.2116 9.54657 19.0321L1.21323 13.6154C0.976738 13.4617 0.834056 13.1988 0.834056 12.9167V7.08337C0.834056 6.80131 0.976738 6.53839 1.21323 6.38467L9.54657 0.968004ZM2.50072 7.53561V12.4645L10.0007 17.3395L17.5007 12.4645V7.53561L10.0007 2.66061L2.50072 7.53561Z" fill="#BDBDBD" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0007 12.0834C10.461 12.0834 10.8341 12.4565 10.8341 12.9167V18.3334C10.8341 18.7936 10.461 19.1667 10.0007 19.1667C9.54049 19.1667 9.16739 18.7936 9.16739 18.3334V12.9167C9.16739 12.4565 9.54049 12.0834 10.0007 12.0834Z" fill="#BDBDBD" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.984696 6.60549C1.24862 6.22845 1.76823 6.13675 2.14527 6.40068L10.0007 11.8995L17.8562 6.40068C18.2332 6.13675 18.7528 6.22845 19.0167 6.60549C19.2807 6.98253 19.189 7.50214 18.8119 7.76607L10.4786 13.5994C10.1917 13.8003 9.80977 13.8003 9.52284 13.5994L1.1895 7.76607C0.812463 7.50214 0.720767 6.98253 0.984696 6.60549Z" fill="#BDBDBD" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.52284 6.40068C9.80977 6.19983 10.1917 6.19983 10.4786 6.40068L18.8119 12.234C19.189 12.4979 19.2807 13.0176 19.0167 13.3946C18.7528 13.7716 18.2332 13.8633 17.8562 13.5994L10.0007 8.10059L2.14527 13.5994C1.76823 13.8633 1.24862 13.7716 0.984696 13.3946C0.720767 13.0176 0.812463 12.4979 1.1895 12.234L9.52284 6.40068Z" fill="#BDBDBD" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0007 0.833374C10.461 0.833374 10.8341 1.20647 10.8341 1.66671V7.08337C10.8341 7.54361 10.461 7.91671 10.0007 7.91671C9.54049 7.91671 9.16739 7.54361 9.16739 7.08337V1.66671C9.16739 1.20647 9.54049 0.833374 10.0007 0.833374Z" fill="#BDBDBD" />
          </svg>
          <h3 class="text-xl pl-4">Payment services</h3>
        </div>

        <hr class="line-hr mb-8 mt-2" />
      </vs-col>
      <div class="product-form">
        <vs-col vs-w="12" class="mb-8">
          <p class="mb-0 text-base instruction-style">
            Payment services enable you to receive simple payments directly from a your customers via these methods, without providing a finance option.
          </p>
        </vs-col>
      </div>
    </vs-row>

    <vs-row v-if="product.productOption.includes('pay-now') && product.payNowOptions.includes('CARDS')" id="payNowArea">
      <vs-col vs-w="12">
        <div class="flex items-center">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" >
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.54657 0.968004C9.82273 0.788497 10.1787 0.788497 10.4549 0.968004L18.7882 6.38467C19.0247 6.53839 19.1674 6.80131 19.1674 7.08337V12.9167C19.1674 13.1988 19.0247 13.4617 18.7882 13.6154L10.4549 19.0321C10.1787 19.2116 9.82273 19.2116 9.54657 19.0321L1.21323 13.6154C0.976738 13.4617 0.834056 13.1988 0.834056 12.9167V7.08337C0.834056 6.80131 0.976738 6.53839 1.21323 6.38467L9.54657 0.968004ZM2.50072 7.53561V12.4645L10.0007 17.3395L17.5007 12.4645V7.53561L10.0007 2.66061L2.50072 7.53561Z" fill="#BDBDBD" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0007 12.0834C10.461 12.0834 10.8341 12.4565 10.8341 12.9167V18.3334C10.8341 18.7936 10.461 19.1667 10.0007 19.1667C9.54049 19.1667 9.16739 18.7936 9.16739 18.3334V12.9167C9.16739 12.4565 9.54049 12.0834 10.0007 12.0834Z" fill="#BDBDBD" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.984696 6.60549C1.24862 6.22845 1.76823 6.13675 2.14527 6.40068L10.0007 11.8995L17.8562 6.40068C18.2332 6.13675 18.7528 6.22845 19.0167 6.60549C19.2807 6.98253 19.189 7.50214 18.8119 7.76607L10.4786 13.5994C10.1917 13.8003 9.80977 13.8003 9.52284 13.5994L1.1895 7.76607C0.812463 7.50214 0.720767 6.98253 0.984696 6.60549Z" fill="#BDBDBD" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.52284 6.40068C9.80977 6.19983 10.1917 6.19983 10.4786 6.40068L18.8119 12.234C19.189 12.4979 19.2807 13.0176 19.0167 13.3946C18.7528 13.7716 18.2332 13.8633 17.8562 13.5994L10.0007 8.10059L2.14527 13.5994C1.76823 13.8633 1.24862 13.7716 0.984696 13.3946C0.720767 13.0176 0.812463 12.4979 1.1895 12.234L9.52284 6.40068Z" fill="#BDBDBD" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0007 0.833374C10.461 0.833374 10.8341 1.20647 10.8341 1.66671V7.08337C10.8341 7.54361 10.461 7.91671 10.0007 7.91671C9.54049 7.91671 9.16739 7.54361 9.16739 7.08337V1.66671C9.16739 1.20647 9.54049 0.833374 10.0007 0.833374Z" fill="#BDBDBD" />
          </svg>
          <h3 id="paynowProduct" class="text-xl pl-4">Card payments</h3>
        </div>
        <hr class="line-hr mt-2 mb-8" />
      </vs-col>

      <vs-col vs-w="12" class="product-form">
        <vs-row class="mb-8">
          <p class="mb-0 text-base instruction-style" v-if="!errors.has('payNowPaymentMethods')">
            We'll enable these cards on your account by default, with the card surcharge shown to be added to each card transaction (paid by the payer, not you).
          </p>

          <span v-show="errors.has('payNowPaymentMethods')" class="text-danger text-sm">Select the payment options you'd like to enable on your account.</span>
        </vs-row>
        <vs-row class="paymentOpt">
          <div v-for="(paynowPlan, key) in product.payNowPaymentMethods" :key="key" class="radio-card big" v-bind:class="{ active: paynowPlan.isChecked == 1 }">
            <vs-row class="flex">
              <div class="flex items-center">
                <S3ImageGenerator :document="paynowPlan.icon" :is-base64="false" :custom-class="'card-payment-method'" />
                <h6 class="pl-2 pr-4 m-0 font-normal" v-bind:class="{ activeHeader: paynowPlan.isChecked == 1 }">
                  {{ paynowPlan.title }}
                </h6>
              </div>

              <div class="ml-auto">
                <vs-checkbox @change="checkIfCardIsComplete('pay-now')" v-model="paynowPlan.isChecked" vs-value="1" vs-name="mastercard" class="m-0" style="margin-right: -1rem !important"></vs-checkbox>
              </div>
            </vs-row>
            <vs-row>
              <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                <div class="mb-8">
                  <p class="w-full mb-2 text-base" style="color: #828282">
                    Surcharge added: <span style="color: #05053f" class="text-base font-light ml-1">{{ `${addZeroes(parseFloat(paynowPlan.surcharge))} %` }}</span>
                  </p>
                </div>
                <div class="mb-2" v-if="paynowPlan.slug == 'amex'">
                  <p class="w-full mb-0 text-base font-light" style="color: #828282">
                    AMEX merchant account ID <span class="mid-blue" v-if="paynowPlan.isChecked">*</span>
                    <vs-tooltip active text="A credit card surcharge is the payment processing fee that will be charged to customers paying by card. The surcharge is to recover the cost of processing credit or debit card transactions. You cannot charge a customer more than it costs you to process the transaction. If the amount is less than merchant service fee, your business will absorb the difference." position="top" class="icon-tooltip"></vs-tooltip>
                  </p>
                  <vs-input
                    v-validate="{ required: paynowPlan.isChecked ? true : false }"
                    class="w-full"
                    size="large"
                    :name="`merchantAccountId${paynowPlan._id}`"
                    v-model="paynowPlan.merchantAccountId"
                    placeholder="Amex merchant account id"
                    data-vv-as="merchant account id"
                  />
                  <span v-show="errors.has(`merchantAccountId${paynowPlan._id}`)" class="text-danger text-sm">{{ errors.first(`merchantAccountId${paynowPlan._id}`) }}</span>
                </div>
              </vs-col>
            </vs-row>
          </div>
        </vs-row>
      </vs-col>
    </vs-row>

    <vs-row v-if="product.productOption.includes('pay-now') && product.payNowOptions.includes('EFT')" class="mt-8">
      <vs-col vs-w="12">
        <div class="flex items-center">
          <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.11191 11.9826H1.22775V15.0771H2.11191V11.9826ZM4.76437 11.9826H3.88022V15.0771H4.76437V11.9826ZM8.52203 15.9613H0.122559V16.8455H8.52203V15.9613ZM7.41684 11.9826H6.53268V15.0771H7.41684V11.9826ZM4.3223 9.003L6.62552 10.2143H2.01907L4.3223 9.003ZM4.3223 8.00391L0.122559 10.2143V11.0984H8.52203V10.2143L4.3223 8.00391Z" fill="#595959"/>
            <path d="M11.4679 3.9826H10.5837V7.07715H11.4679V3.9826ZM14.1203 3.9826H13.2362V7.07715H14.1203V3.9826ZM17.878 7.9613H9.47852V8.84546H17.878V7.9613ZM16.7728 3.9826H15.8886V7.07715H16.7728V3.9826ZM13.6783 1.003L15.9815 2.21429H11.375L13.6783 1.003ZM13.6783 0.00390625L9.47852 2.21429V3.09845H17.878V2.21429L13.6783 0.00390625Z" fill="#595959"/>
            <path d="M13.3441 13.319C13.8776 12.6397 14.0386 11.7855 13.8544 11.0045L13.2765 11.7404L12.7373 11.3169L14.0077 9.69961L15.625 10.9699L15.2015 11.509L14.5546 11.0009C14.7278 11.9346 14.5162 12.9364 13.8832 13.7424C12.7844 15.1414 10.8177 15.4534 9.35062 14.519L9.77829 13.9745C10.9441 14.6766 12.4824 14.416 13.3441 13.319Z" fill="#595959"/>
          </svg>

          <h3 id="paynowProduct" class="text-xl pl-4">EFT payments</h3>
        </div>
        <hr class="line-hr mt-2 mb-8" />
      </vs-col>

      <vs-col vs-w="12" class="product-form">
        <vs-row class="paymentOpt">
          <div class="radio-card big" v-bind:class="{ active: product.eftEnabled }">
            <vs-row class="flex">
              <div class="flex items-center">
                <img :src="payId" alt="payId" height="20" />
                <h6 class="pl-2 pr-4 m-0 font-normal" v-bind:class="{ activeHeader: product.eftEnabled }">EFT / PayID</h6>
              </div>
              <div class="ml-auto">
                <vs-checkbox v-model="product.eftEnabled" :vs-value="true" vs-name="eftType" @change="handleEftChange" class="m-0" style="margin-right: -1rem !important"></vs-checkbox>
              </div>
            </vs-row>
            <vs-row>
              <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                <div class="mb-8">
                  <p class="w-full mb-2 text-base" style="color: #828282">
                    EFT payments securely processed by {{appName}} enabled with PayID, deposited to your nominated account
                  </p>
                </div>
              </vs-col>
            </vs-row>
          </div>
        </vs-row>
      </vs-col>
    </vs-row>

    <vs-row class="mt-8" id="paylaterArea">
      <vs-col vs-w="12">
        <div class="flex items-center">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.54657 0.968004C9.82273 0.788497 10.1787 0.788497 10.4549 0.968004L18.7882 6.38467C19.0247 6.53839 19.1674 6.80131 19.1674 7.08337V12.9167C19.1674 13.1988 19.0247 13.4617 18.7882 13.6154L10.4549 19.0321C10.1787 19.2116 9.82273 19.2116 9.54657 19.0321L1.21323 13.6154C0.976738 13.4617 0.834056 13.1988 0.834056 12.9167V7.08337C0.834056 6.80131 0.976738 6.53839 1.21323 6.38467L9.54657 0.968004ZM2.50072 7.53561V12.4645L10.0007 17.3395L17.5007 12.4645V7.53561L10.0007 2.66061L2.50072 7.53561Z" fill="#BDBDBD" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0007 12.0834C10.461 12.0834 10.8341 12.4565 10.8341 12.9167V18.3334C10.8341 18.7936 10.461 19.1667 10.0007 19.1667C9.54049 19.1667 9.16739 18.7936 9.16739 18.3334V12.9167C9.16739 12.4565 9.54049 12.0834 10.0007 12.0834Z" fill="#BDBDBD" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.984696 6.60549C1.24862 6.22845 1.76823 6.13675 2.14527 6.40068L10.0007 11.8995L17.8562 6.40068C18.2332 6.13675 18.7528 6.22845 19.0167 6.60549C19.2807 6.98253 19.189 7.50214 18.8119 7.76607L10.4786 13.5994C10.1917 13.8003 9.80977 13.8003 9.52284 13.5994L1.1895 7.76607C0.812463 7.50214 0.720767 6.98253 0.984696 6.60549Z" fill="#BDBDBD" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.52284 6.40068C9.80977 6.19983 10.1917 6.19983 10.4786 6.40068L18.8119 12.234C19.189 12.4979 19.2807 13.0176 19.0167 13.3946C18.7528 13.7716 18.2332 13.8633 17.8562 13.5994L10.0007 8.10059L2.14527 13.5994C1.76823 13.8633 1.24862 13.7716 0.984696 13.3946C0.720767 13.0176 0.812463 12.4979 1.1895 12.234L9.52284 6.40068Z" fill="#BDBDBD" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0007 0.833374C10.461 0.833374 10.8341 1.20647 10.8341 1.66671V7.08337C10.8341 7.54361 10.461 7.91671 10.0007 7.91671C9.54049 7.91671 9.16739 7.54361 9.16739 7.08337V1.66671C9.16739 1.20647 9.54049 0.833374 10.0007 0.833374Z" fill="#BDBDBD" />
          </svg>
          <h3 id="paylaterProduct" class="text-xl pl-4">Finance options</h3>
        </div>
        <hr class="line-hr mt-2 mb-8" />
      </vs-col>

      <vs-col vs-w="12" class="product-form">
        <vs-row class="mb-8" v-if="!product.payLaterProducts || !product.payLaterProducts.length">
          <p class="mb-0 text-base instruction-style">No finance options requested - contact us to explore options for your business & customers.</p>
        </vs-row>
        <vs-row class="mb-8" v-else>
          <p class="mb-0 text-base instruction-style" v-if="!errors.has('payLaterPaymentMethods')">Confirm the finance options to enable on your account.</p>
          <span v-show="errors.has('payLaterPaymentMethods')" class="text-danger text-sm">Select the finance options you would like to enable.</span>
        </vs-row>
        <vs-row class="paymentOpt">
          <div v-for="(payLaterPlan, key) in product.payLaterProducts" :key="key" class="radio-card big" v-bind:class="{ active: payLaterPlan.isChecked == 1 || payLaterPlan.isChecked }">
            <vs-row>
              <vs-col vs-w="10" vs-sm="10" vs-lg="10" vs-xs="10" class="radio-label">
                <h4 class="text-xl font-normal mb-0" v-bind:class="{ activeHeader: payLaterPlan.isChecked == 1 || payLaterPlan.isChecked }">
                  {{ payLaterPlan.productName }}
                </h4>
              </vs-col>
              <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2" class="flex justify-end">
                <vs-checkbox @change="checkIfCardIsComplete('pay-later')" v-model="payLaterPlan.isChecked" vs-name="mastercard" class="items-start" style="margin-right: -1rem !important"></vs-checkbox>
              </vs-col>

              <p class="text-base font-light mb-1 mt-2">
                {{ payLaterPlan.productDescription }}
              </p>
            </vs-row>
            <vs-row>
              <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                <div>
                  <p class="text-base font-light mb-0">
                    Service fee:
                    <span style="color: #05053f" class="text-base font-light ml-1">{{ !isNaN(payLaterPlan.msf) ? `${addZeroes(parseFloat(payLaterPlan.msf))} %` : payLaterPlan.msf }}</span>
                    <span class="text-xs">{{ payLaterPlan.feeModel == "MSF_INCLUDED" ?  " (payable by you)" : " (payable by Customer)" }}</span>
                  </p>
                  <p class="text-base font-light mb-0" v-if="payLaterPlan.minimumFeeAmount > 0">
                    Minimum fee: <span style="color: #05053f" class="text-base font-light ml-1">$ {{ addZeroes(parseFloat(payLaterPlan.minimumFeeAmount)) }}</span>
                  </p>
                  <p class="text-base font-light mb-0" v-if="payLaterPlan.initialPeriod">
                    Period: <span style="color: #05053f" class="text-base font-light ml-1">{{ payLaterPlan.initialPeriod }} days</span>
                  </p>
                  <p class="text-base font-light mb-0" v-if="payLaterPlan.noOfPayments">
                    Schedule: <span style="color: #05053f" class="text-base font-light ml-1">{{ `${payLaterPlan.noOfPayments} payments, ${payLaterPlan.paymentFrequency.toLowerCase()}` }}</span>
                  </p>
                  <p class="text-base font-light mb-0">
                    Limit per request: <span style="color: #05053f" class="text-base font-light ml-1">$ {{ addZeroes(parseFloat(payLaterPlan.maximumAmount)) }}</span>
                  </p>
                  <a class="text-base font-light" @click="handleTermsClick(payLaterPlan.customPlanId)">Terms & conditions</a>

                  <div class="mt-5" v-if="banks && banks.length > 1 && !(payLaterPlan.productConfigType === 'THREE_PARTY' && payLaterPlan.disburseTo == 'external')">
                    <p class="text-xs">Product can be used with these bank accounts (you can update this later)</p>
                    <div class="my-4">
                      <template v-for="(bank, index) in banks">
                        <vs-checkbox v-model="payLaterPlan.banks" :name="`banks[${payLaterPlan._id}]`" :vs-name="`banks[${payLaterPlan._id}]`" class="mt-2 ml-0" v-validate="'required'" data-vv-as="linked bank account" :vs-value="`${bank.bsb}/${bank.accountNumber}/${bank.accountName}`" @change="handleBankUpdate(key)">
                          <p class="text-xs"> {{ `${bank.accountName} (${bank.bsb} / ${bank.accountNumber})` }} </p>
                        </vs-checkbox>
                      </template>
                    </div>

                    <span v-show="errors.has(`banks[${payLaterPlan._id}]`)" class="text-danger text-sm">At least one account must be selected</span>
                  </div>
                </div>
              </vs-col>
            </vs-row>
          </div>
        </vs-row>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
const tooltip = require("@/assets/images/tooltip-blue.svg");
const calenderImage = require("@/assets/images/calender.png");
import { TheMask } from "vue-the-mask";
import { Money } from "v-money";
import moment from "moment";
const update = require("@/assets/images/update.png");
const playCircle = require("@/assets/images/play-circle.png");
const stopCircle = require("@/assets/images/stop-circle.png");
const bankTransfer = require("@/assets/images/cards/banktransfer.svg");
const payId = require("@/assets/images/cards/payid.svg");

import { Validator } from "vee-validate";
import S3ImageGenerator from "@/views/onboarding/components/S3ImageGenerator";

const dictionary = {
  en: {
    attributes: {},
  },
};

Validator.localize(dictionary);

export default {
  name: "ProductSelection",
  props: {
    applicationStatus: { type: String },
    banks: { type: Array },
    isComplete: { type: Object },
    product: { type: Object },
  },

  components: { TheMask, Money, S3ImageGenerator },

  data() {
    return {
      appName: process.env.VUE_APP_NAME == "FlipPay" ? "Flip" : "Rello",
      bankTransfer: bankTransfer,
      payId: payId,
      calenderImage: calenderImage,
      update: update,
      playCircle: playCircle,
      stopCircle: stopCircle,
      hasBeenCompleted: {},
      rawProducts: [],
      tooltip: tooltip,
      money2: {
        precision: 2,
        suffix: " %",
      },
      payload: [],
    };
  },

  methods: {
    ...mapActions("onboardMerchant", ["fetchPaymentPlanDetail"]),

    handleTermsClick(planId) {
      window.open(`${process.env.VUE_APP_ADMIN_URL}products/${planId}/borrower`, "_blank");
    },

    handleBankUpdate(index) {
      this.product.payLaterProducts[index].bankUpdated = true;
      this.$forcerUpdate();
    },

    removePercentage(num) {
      return parseFloat(num.replace("%", ""));
    },

    changeMessage(data, removeString, addString) {
      return data ? data.replace(removeString, addString) : "";
    },

    validateForm() {
      if (this.product.productOption.length == 0) {
        this.errors.add({
          field: "payNowN",
          msg: "m",
        });
        this.$validator.validateAll();
        return false;
      }

      if (this.product.productOption.includes("pay-now")) {
        this.product.payNowPaymentMethods.forEach((method, index) => {
          let surcharge = parseFloat(method.surcharge);
          let msf = parseFloat(method.msf.replace("%", ""));

          if (surcharge > msf) {
            this.errors.add({
              field: `surcharge[${index}]`,
              msg: "Card surcharge can’t be greater than the MSF",
            });
          } else {
            this.errors.remove(`surcharge[${index}]`);
          }
        });

        let isCardSelected = this.product.payNowPaymentMethods.some(
          (method) => {
            if (method.isChecked && method.isChecked == "1") {
              return true;
            }
          }
        );
      }

      if (this.product.productOption.includes("pay-later")) {
        let isCardSelected = this.product.payLaterProducts.some((method) => {
          if (method.isChecked && method.isChecked == "1") {
            return true;
          }
        });

        if (!isCardSelected) {
          this.errors.add({
            field: "payLaterPaymentMethods",
            msg: "m",
          });
        }
      }
      return this.$validator.validateAll();
    },

    checkIfCardIsComplete(slug = "") {
      let self = this;

      if (slug === "pay-later") {
        let isCardSelected = this.product.payLaterProducts.some((method) => {
          if (method.isChecked && method.isChecked == "1") {
            return true;
          }
        });

        if (!isCardSelected) {
          this.errors.add({
            field: "payLaterPaymentMethods",
            msg: "m",
          });
        } else {
          this.errors.remove("payLaterPaymentMethods");
        }
      }

      let payNow = false;
      let payLater = false;

      if (this.product.productOption.length === 0) {
        payNow = false;
        payLater = false;
      } else {
        payNow = true;
        payLater = true;
      }

      self.product.payNowPaymentMethods.forEach((method, index) => {
        let surcharge = parseFloat(method.surcharge);
        let msf = parseFloat(method.msf.replace("%", ""));

        if (surcharge > msf) {
          this.errors.add({
            field: `surcharge[${index}]`,
            msg: "Card surcharge can't be greater than the MSF",
          });
        } else {
          this.errors.remove(`surcharge[${index}]`);
        }
      });

      for (const product of this.product.productOption) {
        if (this.rawProducts.includes(product)) {
          if (product === "pay-now") {
            const mySelectedProduct = this.product.payNowPaymentMethods.filter((row) => row.isChecked == 1);

            const surchargeError = mySelectedProduct.some((row, index) => (typeof row.surcharge === "string" &&
              (row.surcharge.length === 0 || parseFloat(row.surcharge) > parseFloat(row.msf))) ||
              parseFloat(row.surcharge) < 0.0 || this.errors.has(`surcharge[${index}]`)
            );

            payNow = !surchargeError;
          }

          if (product === "pay-later") {
            const mySelectedProductLater = this.product.payLaterProducts.filter((row) => row.isChecked === true);

            if (mySelectedProductLater.length === 0) {
              payLater = false;
            } else {
              payLater = true;
            }
          }

          this.$emit("handleCardHeight");
        }
      }

      this.isComplete.productSelectionStatus = payNow && payLater;
      this.$emit("checkDirty", true);
    },

    addZeroes(num) {
      num = String(num);
      return Number(num).toLocaleString(undefined, {
        minimumFractionDigits: 2,
      });
    },

    async payLaterDetail(planId, fields) {
      return await this.fetchPaymentPlanDetail(planId)
        .then((res) => {
          if (res.data.data && res.data.data.length) {
            const { msf, ...rest } = res.data.data[0];
            const newProductLegals = rest.legals.map((legalsData) => {
              return {
                _id: legalsData._id,
                version: legalsData.version,
                type: legalsData.type,
                user: legalsData.user
              };
            });
            rest.legals = newProductLegals;
            this.product.payLaterProducts = [
              ...this.product.payLaterProducts.filter((later) => later.customPlanId === planId).map((item) => ({
                ...fields,
                ...rest,
                banks: item.banks ? item.banks : this.banks.map(item => `${item.bsb}/${item.accountNumber}/${item.accountName}`).filter(item => item),
                bankUpdated: (item.banks && item.banks.length) ? true : false
              })),
              ...this.product.payLaterProducts.filter((later) => later.customPlanId !== planId),
            ];
          } else { // IF THE PLAN IS DELETED THEN REMOVEING THAT PLAN FROM LIST
            const index = this.product.payLaterProducts.findIndex(item => item.customPlanId === planId);
            if (index >= 0) {
              this.product.payLaterProducts.splice(index, 1);
              this.$forcerUpdate();
            }
          }
        })
        .catch((ex) => {});
    },

    handleChange(index, flag) {
      if (this.applicationStatus === "Info Requested") {
        let changedlabel = `payNowPaymentMethods[${index}]${flag}`;

        const data = {
          label: changedlabel,
          oldValue: this.payload.payNowPaymentMethods[index][flag],
          newValue: this.product.payNowPaymentMethods[index][flag]
        };

        this.$emit("resubmit", data);
      }
      this.checkIfCardIsComplete(flag);
    },

    handleEftChange() {
      this.product.eftType = this.product.eftEnabled ? "platform" : "merchant";
    }
  },
  created() {
    this.hasBeenCompleted = this.isComplete;
    this.payload = JSON.parse(JSON.stringify(this.product));
    this.checkIfCardIsComplete();
    this.$validator.extend("surcharge", {
      getMessage: () => "Surcharge must be less then MSF.",
      validate: (value) =>
        value.match(
          /^(^04\(?\d{2}\)?[\s.-]\d{3}[\s.-]\d{3})|(^03\(?\d{2}\)?[\s.-]\d{3}[\s.-]\d{3})|(^02\(?\d{2}\)?[\s.-]\d{3}[\s.-]\d{3})|(^07\(?\d{2}\)?[\s.-]\d{3}[\s.-]\d{3})|(^08\(?\d{2}\)?[\s.-]\d{3}[\s.-]\d{3})|(^13\(?\d{2}\)?[\s.-]\d{2}$)|(^1300[\s.-]\d{3}[\s.-]\d{3})|(^05\(?\d{2}\)?[\s.-]\d{3}[\s.-]\d{3})|(^18\(?\d{2}\)?[\s.-]\d{3}[\s.-]\d{3})/
        ) !== null,
    });
    let isProductSet = true;
    let ispayNowPaymentMethodsSet = true;
    let isPayNowOptionsSet = true;

    if (this.product.payLaterProducts.length == 0) {
      isProductSet = false;
    }

    if (!this.product.payNowPaymentMethods || this.product.payNowPaymentMethods.length == 0) {
      ispayNowPaymentMethodsSet = false
    }

    this.rawProducts = this.appActiveUser.paymentOptions.requestOptions;

    if (!this.product.payNowOptions || (this.rawProducts.includes("pay-now") && this.product.payNowOptions.length == 0)) {
      isPayNowOptionsSet = false;
    }

    if (!isPayNowOptionsSet && this.rawProducts.includes("pay-now")) {
      this.product.payNowOptions = (this.appActiveUser.payNowOptions && this.appActiveUser.payNowOptions.length) ? this.appActiveUser.payNowOptions : ["CARDS", "EFT"];
      this.product.eftEnabled = this.product.payNowOptions.includes("EFT");
      this.product.eftType = this.product.eftEnabled ? "platform" : "merchant";
    } else if (!isPayNowOptionsSet) {
      this.product.payNowOptions = [];
      this.product.eftEnabled = false;
      this.product.eftType = "merchant";
    }

    if (!ispayNowPaymentMethodsSet && this.product.payNowOptions.includes("CARDS")) {
      this.product.payNowPaymentMethods = this.rawProducts.includes("pay-now")
        ? [
            ...this.appActiveUser.cardOptions.map(({ isChecked, ...rest }) => ({
              isChecked: true,
              ...rest,
            })),
          ]
        : [];
    }
    if (!isProductSet) {
      this.product.productOption = this.rawProducts;
      this.product.payLaterProducts = this.appActiveUser.paymentOptions.payLaterPlan;

      if (this.rawProducts.includes("pay-later") && this.product.payLaterProducts.length > 0) {
        for (const d of this.product.payLaterProducts) {
          this.payLaterDetail(d.customPlanId, { msf: d.msf, isChecked: true });
        }
      } else {
        this.product.payLaterProducts = [];
      }

      this.isComplete.productSelectionStatus = true;
    }

    if (this.rawProducts.includes("pay-now") && this.product.payNowPaymentMethods.length > 0) {
      for (const d of this.product.payNowPaymentMethods) {
        d.surcharge = this.addZeroes(parseFloat(d.surcharge));
      }
    }
  },

  computed: {
    appActiveUser() {
      return Object.assign({}, this.$store.state.AppActiveUser);
    },
  },

  watch: {
    "product.productOption": function (newVal, oldVal) {
      if (newVal.length != oldVal.length) {
        if (this.product.productOption.length > 0) {
          this.errors.remove("payNowN");
        } else {
          this.errors.add({
            field: "payNowN",
            msg: "Wrong Credentials",
          });
        }
      }
    },
    banks: {
      handler(newBank, oldBank) {
        const product = this.product.payLaterProducts || [];

        if (product && product.length) {
          this.product.payLaterProducts.map(item => {
            if (!item.bankUpdated) {
              item.banks = newBank.map(item => `${item.bsb}/${item.accountNumber}/${item.accountName}`);
            }
            return item;
          })
        }
      },
      deep: true,
    }
  },
};
</script>

