export default [
  {
    industry: "Health care and social assistance",
    name: "Aged care residential services"
  },
  {
    industry: "Health care and social assistance",
    name: "Ambulance services"
  },
  {
    industry: "Health care and social assistance",
    name: "Child care services"
  },
  {
    industry: "Health care and social assistance",
    name: "Chiropractic and osteopathic services"
  },
  {
    industry: "Health care and social assistance",
    name: "Dental services"
  },
  {
    industry: "Health care and social assistance",
    name: "General practice medical services"
  },
  {
    industry: "Health care and social assistance",
    name: "Hospitals (except psychiatric hospitals)"
  },
  {
    industry: "Health care and social assistance",
    name: "Optometry and optical dispensing"
  },
  {
    industry: "Health care and social assistance",
    name: "Other allied health services"
  },
  {
    industry: "Health care and social assistance",
    name: "Other health care services nec"
  },
  {
    industry: "Health care and social assistance",
    name: "Other residential care services"
  },
  {
    industry: "Health care and social assistance",
    name: "Other social assistance services"
  },
  {
    industry: "Health care and social assistance",
    name: "Pathology and diagnostic imaging services"
  },
  {
    industry: "Health care and social assistance",
    name: "Physiotherapy services"
  },
  {
    industry: "Health care and social assistance",
    name: "Psychiatric hospitals"
  },
  {
    industry: "Health care and social assistance",
    name: "Specialist medical services"
  },
]
