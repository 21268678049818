export default [
  {
    industry: "Retail trade",
    name: "Antique and used goods retailing"
  },
  {
    industry: "Retail trade",
    name: "Car retailing"
  },
  {
    industry: "Retail trade",
    name: "Clothing retailing"
  },
  {
    industry: "Retail trade",
    name: "Computer and computer peripheral retailing"
  },
  {
    industry: "Retail trade",
    name: "Department stores"
  },
  {
    industry: "Retail trade",
    name: "Electrical, electronic and gas appliance retailing"
  },
  {
    industry: "Retail trade",
    name: "Entertainment media retailing"
  },
  {
    industry: "Retail trade",
    name: "Floor coverings retailing"
  },
  {
    industry: "Retail trade",
    name: "Flower retailing"
  },
  {
    industry: "Retail trade",
    name: "Footwear retailing"
  },
  {
    industry: "Retail trade",
    name: "Fresh meat, fish and poultry retailing"
  },
  {
    industry: "Retail trade",
    name: "Fruit and vegetable retailing"
  },
  {
    industry: "Retail trade",
    name: "Fuel retailing"
  },
  {
    industry: "Retail trade",
    name: "Furniture retailing"
  },
  {
    industry: "Retail trade",
    name: "Garden supplies retailing"
  },
  {
    industry: "Retail trade",
    name: "Hardware and building supplies retailing"
  },
  {
    industry: "Retail trade",
    name: "Houseware retailing"
  },
  {
    industry: "Retail trade",
    name: "Liquor retailing"
  },
  {
    industry: "Retail trade",
    name: "Manchester and other textile goods retailing"
  },
  {
    industry: "Retail trade",
    name: "Marine equipment retailing"
  },
  {
    industry: "Retail trade",
    name: "Motor cycle retailing"
  },
  {
    industry: "Retail trade",
    name: "Motor vehicle parts retailing"
  },
  {
    industry: "Retail trade",
    name: "Newspaper and book retailing"
  },
  {
    industry: "Retail trade",
    name: "Non-store retailing"
  },
  {
    industry: "Retail trade",
    name: "Other electrical and electronic goods retailing"
  },
  {
    industry: "Retail trade",
    name: "Other personal accessory retailing"
  },
  {
    industry: "Retail trade",
    name: "Other specialised food retailing"
  },
  {
    industry: "Retail trade",
    name: "Other store-based retailing nec"
  },
  {
    industry: "Retail trade",
    name: "Pharmaceutical, cosmetic and toiletry goods retailing"
  },
  {
    industry: "Retail trade",
    name: "Retail commission-based buying and/or selling"
  },
  {
    industry: "Retail trade",
    name: "Sport and camping equipment retailing"
  },
  {
    industry: "Retail trade",
    name: "Stationery goods retailing"
  },
  {
    industry: "Retail trade",
    name: "Supermarket and grocery stores"
  },
  {
    industry: "Retail trade",
    name: "Toy and game retailing"
  },
  {
    industry: "Retail trade",
    name: "Trailer and other motor vehicle retailing"
  },
  {
    industry: "Retail trade",
    name: "Tyre retailing"
  },
  {
    industry: "Retail trade",
    name: "Watch and jewellery retailing"
  },
]
