export default [
  {
    industry: "Administrative and support services",
    name: "Building and other industrial cleaning services"
  },
  {
    industry: "Administrative and support services",
    name: "Building pest control services"
  },
  {
    industry: "Administrative and support services",
    name: "Call centre operation"
  },
  {
    industry: "Administrative and support services",
    name: "Credit reporting and debt collection services"
  },
  {
    industry: "Administrative and support services",
    name: "Document preparation services"
  },
  {
    industry: "Administrative and support services",
    name: "Employment placement and recruitment services"
  },
  {
    industry: "Administrative and support services",
    name: "Gardening services"
  },
  {
    industry: "Administrative and support services",
    name: "Labour supply services"
  },
  {
    industry: "Administrative and support services",
    name: "Office administrative services"
  },
  {
    industry: "Administrative and support services",
    name: "Other administrative services nec"
  },
  {
    industry: "Administrative and support services",
    name: "Packaging services"
  },
  {
    industry: "Administrative and support services",
    name: "Travel agency service and tour arrangement services"
  },
]
