export default [
  {
    industry: "Agriculture, forestry and fishing",
    name: "Apple and pear growing"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Beef cattle farming (specialised)"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Beef cattle feedlots (specialised)"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Beekeeping"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Berry fruit growing"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Citrus fruit growing"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Cotton ginning"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Cotton growing"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Dairy cattle farming"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Deer farming"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Fish trawling, seining and netting"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Floriculture production (outdoors)"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Floriculture production (under cover)"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Forestry"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Forestry support services"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Grain-sheep or grain-beef cattle farming"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Grape growing"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Horse farming"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Hunting and trapping"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Kiwifruit growing"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Line fishing"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Logging"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Mushroom growing"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Nursery production (outdoors)"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Nursery production (under cover)"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Offshore caged aquaculture"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Offshore longline and rack aquaculture"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Olive growing"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Onshore aquaculture"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Other agriculture and fishing support services"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Other crop growing nec"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Other fishing"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Other fruit and tree nut growing"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Other grain growing"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Other livestock farming nec"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Pig farming"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Poultry farming (eggs)"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Poultry farming (meat)"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Prawn fishing"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Rice growing"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Rock lobster and crab potting"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Shearing services"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Sheep farming (specialised)"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Sheep-beef cattle farming"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Stone fruit growing"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Sugar cane growing"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Turf growing"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Vegetable growing (outdoors)"
  },
  {
    industry: "Agriculture, forestry and fishing",
    name: "Vegetable growing (under cover)"
  },
]
