export default [
  {
    industry: "Arts and recreation services",
    name: "Amusement and other recreational activities nec"
  },
  {
    industry: "Arts and recreation services",
    name: "Amusement parks and centres operation"
  },
  {
    industry: "Arts and recreation services",
    name: "Casino operation"
  },
  {
    industry: "Arts and recreation services",
    name: "Creative artists, musicians, writers and performers"
  },
  {
    industry: "Arts and recreation services",
    name: "Health and fitness centres and gymnasia operation"
  },
  {
    industry: "Arts and recreation services",
    name: "Horse and dog racing administration and track operations"
  },
  {
    industry: "Arts and recreation services",
    name: "Lottery operation"
  },
  {
    industry: "Arts and recreation services",
    name: "Museum operation"
  },
  {
    industry: "Arts and recreation services",
    name: "Nature reserves and conservation parks operation"
  },
  {
    industry: "Arts and recreation services",
    name: "Other gambling activities"
  },
  {
    industry: "Arts and recreation services",
    name: "Other horse and dog racing activities"
  },
  {
    industry: "Arts and recreation services",
    name: "Performing arts operation"
  },
  {
    industry: "Arts and recreation services",
    name: "Performing arts venue operation"
  },
  {
    industry: "Arts and recreation services",
    name: "Sports and physical recreation administrative service"
  },
  {
    industry: "Arts and recreation services",
    name: "Sports and physical recreation clubs and sports professionals"
  },
  {
    industry: "Arts and recreation services",
    name: "Sports and physical recreation venues, grounds and facilities operation"
  },
  {
    industry: "Arts and recreation services",
    name: "Zoological and botanical gardens operation"
  },
]
