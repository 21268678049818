export default [
  {
    industry: "Accommodation and food services",
    name: "Accommodation"
  },
  {
    industry: "Accommodation and food services",
    name: "Cafes and restaurants"
  },
  {
    industry: "Accommodation and food services",
    name: "Catering services"
  },
  {
    industry: "Accommodation and food services",
    name: "Clubs(Hospitality)"
  },
  {
    industry: "Accommodation and food services",
    name: "Pubs, tavern and bars"
  },
  {
    industry: "Accommodation and food services",
    name: "Takeaway food services"
  },
];
