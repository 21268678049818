<template>
  <div class="onboarding-form">
    <div class="input-grp">
      <label
      class="vs-input--label w-full"
      :class="errors.has(`Trading name`) ? 'text-danger' : ''"
      >Trading name</label>
      <vs-input
        v-model="generalInformation.tradingName"
        class="w-full"
        @keyup="handleChange('tradingName')"
        @change="handleChange('tradingName')"
        name="Trading name"
        v-validate="'required'"
      />
      <span v-show="errors.has(`Trading name`)" class="text-danger text-sm">
        {{ errors.first(`Trading name`) }}
      </span>
    </div>

    <div class="flex mt-6 flex-wrap">
      <div class="input-grp">
        <label
          class="vs-input--label w-full"
          :class="errors.has(`Industry`) ? 'text-danger' : ''"
          >Industry</label
        >
        <vs-select
          v-model="generalInformation.industry"
          autocomplete
          v-validate="'required'"
          name="Industry"
          class="w-full rounded"
          @change="selectIndustryAndMountCategory($event)"
        >
          <vs-select-item
            v-for="(industry, index) in industries"
            :key="index"
            :text="industry.name"
            :value="industry.name"
          ></vs-select-item>
        </vs-select>

        <span v-show="errors.has(`Industry`)" class="text-danger text-sm">
          {{ errors.first(`Industry`) }}
        </span>
      </div>

      <div class="input-grp">
        <label
          class="vs-input--label w-full"
          :class="errors.has(`Industry`) ? 'text-danger' : ''"
          >Category</label
        >
        <vs-select
          v-model="generalInformation.category"
          autocomplete
          v-validate="'required'"
          class="w-full rounded"
          name="Category"
          @change="handleChange('category')"
        >
          <vs-select-item
            v-for="(category, index) in categories"
            :key="index"
            :text="category.name"
            :value="category.name"
          ></vs-select-item>
        </vs-select>

        <span v-show="errors.has(`Category`)" class="text-danger text-sm">
          {{ errors.first(`Category`) }}
        </span>
      </div>
    </div>

    <div class="flex mt-6 flex-wrap">
      <div class="input-grp">
        <label class="vs-input--label w-full">Website URL</label>
        <vs-input
          type="url"
          name="Website Url"
          v-model="generalInformation.websiteUrl"
          v-validate="'urlFormat'"
          class="w-full"
          @keyup="handleChange('websiteUrl')"
          placeholder="Optional"
        />
        <span v-show="errors.has(`Website Url`)" class="text-danger text-sm">
          {{ errors.first(`Website Url`) }}
        </span>
      </div>


    </div>

    <div class="flex mt-6 flex-wrap">
      <div class="input-grp">
        <label
          class="vs-input--label w-full"
          :class="errors.has(`Business email address`) ? 'text-danger' : ''"
          >Business email address</label
        >
        <vs-input
          name="Business email address"
          v-model="generalInformation.businessEmailAddress"
          v-validate="'required|email'"
          class="w-full"
          @keyup="handleChange('businessEmailAddress')"
          @change="handleChange('businessEmailAddress')"
        />
        <span
          v-show="errors.has(`Business email address`)"
          class="text-danger text-sm"
        >
          {{ errors.first(`Business email address`) }}
        </span>
      </div>

      <div class="input-grp">
        <label
          class="vs-input--label w-full"
          :class="errors.has(`Business phone number`) ? 'text-danger' : ''"
          >Business phone number</label
        >
        <vs-input
          name="Business phone number"
          v-mask="[checkPhoneNumber]"
          v-validate="'required|phoneFormat'"
          v-model="generalInformation.businessPhoneNumber"
          class="w-full surcharge-top"
          @keyup="handleChange('businessPhoneNumber')"
          @change="handleChange('businessPhoneNumber')"
        />
        <span
          v-show="errors.has(`Business phone number`)"
          class="text-danger text-sm"
        >
          {{ errors.first(`Business phone number`) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { TheMask, mask } from "vue-the-mask";
import { mapActions } from "vuex";
import industries from "../resources/industries";
import categories from "../resources/categories";

export default {
  name: "GeneralInformation",
  components: {
    VueGoogleAutocomplete,
    TheMask,
  },
  directives: { mask },
  props: {
    generalInformation: { type: Object },
    isComplete: { type: Object },
    applicationStatus: { type: String},
  },
  data() {
    return {
      industries: [],
      categories: [],
      payload: {},
      hasBeenCompleted: {},
      states: [
        { text: "NSW", value: "NSW" },
        { text: "ACT", value: "ACT" },
        { text: "QLD", value: "QLD" },
        { text: "VIC", value: "VIC" },
        { text: "NT", value: "NT" },
        { text: "SA", value: "SA" },
        { text: "WA", value: "WA" },
        { text: "TAS", value: "TAS" },
      ],
      showAbnFailureMessage: false,
      abnDataFetched: false,
      abnFailureMessage: "",
    };
  },
  created() {
    this.payload = {...this.generalInformation};
    this.hasBeenCompleted = this.isComplete;
    this.industries = industries;
    if (this.generalInformation.industry && this.generalInformation.industry.length > 0) {
      this.categories = categories.filter(
        (category) => category.industry === this.generalInformation.industry
      );
    }
  },
  methods: {

    selectIndustryAndMountCategory(event) {
      this.categories = categories.filter(
        (category) => category.industry === event
      );
      this.generalInformation.category = "";
      this.handleChange('industry');
      this.checkIfCardIsComplete();
    },

    checkIfCardIsComplete() {
      this.hasBeenCompleted.generalInformationStatus =
        this.generalInformation.tradingName && this.generalInformation.tradingName.length > 0 &&
        this.generalInformation.industry && this.generalInformation.industry.length > 0 &&
        this.generalInformation.category && this.generalInformation.category.length > 0 &&
        !this.errors.has("Website Url") &&
        this.generalInformation.businessEmailAddress && this.generalInformation.businessEmailAddress.length > 0 &&
        !this.errors.has(`Business email address`) &&
        this.generalInformation.businessPhoneNumber && this.generalInformation.businessPhoneNumber.length > 0 &&
        !this.errors.has(`Business phone number`);
    },

    validateForm() {
      return this.$validator.validateAll();
    },
    handleChange(label){
      if(this.applicationStatus === 'Info Requested'){
        const data = {
          label: label,
          oldValue: this.payload[label],
          newValue: this.generalInformation[label]
        }
        this.$emit("resubmit", data);
      }
      this.checkIfCardIsComplete();
    }
  },

  computed: {
    checkPhoneNumber() {
      let mask = "#### ### ###";
      if (
        this.generalInformation.businessPhoneNumber &&
        this.generalInformation.businessPhoneNumber.length > 0
      ) {
        let numLength = this.generalInformation.businessPhoneNumber.length;
        let value1 = "";
        let value2 = "";
        if (numLength >= 2) {
          value1 = this.generalInformation.businessPhoneNumber.substring(0, 2);
          if (value1 === "13") {
            value2 = this.generalInformation.businessPhoneNumber.split(" ").join("").substring(0,4);
          }
        }
        if (
          value1 === "02" ||
          value1 === "03" ||
          value1 === "07" ||
          value1 === "08"
        ) {
          mask = "## #### ####";
        } else {
          mask = "#### ### ###";
        }

        if (value1 === "13") {
          if (parseInt(value2) == 1300) {
            mask = "#### ### ###";
          } else {
            mask = "## ## ##";
          }
        }
      }
      return mask;
    },
  },

  watch: {
    isFormDirty(val) {
      if (val) {
        this.$emit("checkDirty", this.isFormDirty);
      }
    },
  },
};
</script>

