export default [
  {
    industry: "Wholesale trade",
    name: "Agricultural and construction machinery wholesaling"
  },
  {
    industry: "Wholesale trade",
    name: "Book and magazine wholesaling"
  },
  {
    industry: "Wholesale trade",
    name: "Car wholesaling"
  },
  {
    industry: "Wholesale trade",
    name: "Cereal grain wholesaling"
  },
  {
    industry: "Wholesale trade",
    name: "Clothing and footwear wholesaling"
  },
  {
    industry: "Wholesale trade",
    name: "Commercial vehicle wholesaling"
  },
  {
    industry: "Wholesale trade",
    name: "Commission-based wholesaling"
  },
  {
    industry: "Wholesale trade",
    name: "Computer and computer peripheral wholesaling"
  },
  {
    industry: "Wholesale trade",
    name: "Dairy produce wholesaling"
  },
  {
    industry: "Wholesale trade",
    name: "Fish and seafood wholesaling"
  },
  {
    industry: "Wholesale trade",
    name: "Fruit and vegetable wholesaling"
  },
  {
    industry: "Wholesale trade",
    name: "Furniture and floor coverings wholesaling"
  },
  {
    industry: "Wholesale trade",
    name: "General line grocery wholesaling"
  },
  {
    industry: "Wholesale trade",
    name: "Industrial and agricultural chemical product wholesaling"
  },
  {
    industry: "Wholesale trade",
    name: "Jewellery and watch wholesaling"
  },
  {
    industry: "Wholesale trade",
    name: "Kitchen and diningware wholesaling"
  },
  {
    industry: "Wholesale trade",
    name: "Liquor and tobacco product wholesaling"
  },
  {
    industry: "Wholesale trade",
    name: "Meat, poultry and smallgoods wholesaling"
  },
  {
    industry: "Wholesale trade",
    name: "Metal and mineral wholesaling"
  },
  {
    industry: "Wholesale trade",
    name: "Motor vehicle dismantling and used parts wholesaling"
  },
  {
    industry: "Wholesale trade",
    name: "Motor vehicle new parts wholesaling"
  },
  {
    industry: "Wholesale trade",
    name: "Other agricultural product wholesaling"
  },
  {
    industry: "Wholesale trade",
    name: "Other electrical and electronic goods wholesaling"
  },
  {
    industry: "Wholesale trade",
    name: "Other goods wholesaling nec"
  },
  {
    industry: "Wholesale trade",
    name: "Other grocery wholesaling"
  },
  {
    industry: "Wholesale trade",
    name: "Other hardware goods wholesaling"
  },
  {
    industry: "Wholesale trade",
    name: "Other machinery and equipment wholesaling nec"
  },
  {
    industry: "Wholesale trade",
    name: "Other specialised industrial machinery and equipment wholesaling"
  },
  {
    industry: "Wholesale trade",
    name: "Paper product wholesaling"
  },
  {
    industry: "Wholesale trade",
    name: "Petroleum product wholesaling"
  },
  {
    industry: "Wholesale trade",
    name: "Pharmaceutical and toiletry goods wholesaling"
  },
  {
    industry: "Wholesale trade",
    name: "Plumbing goods wholesaling"
  },
  {
    industry: "Wholesale trade",
    name: "Professional and scientific goods wholesaling"
  },
  {
    industry: "Wholesale trade",
    name: "Telecommunication goods wholesaling"
  },
  {
    industry: "Wholesale trade",
    name: "Textile product wholesaling"
  },
  {
    industry: "Wholesale trade",
    name: "Timber wholesaling"
  },
  {
    industry: "Wholesale trade",
    name: "Toy and sporting goods wholesaling"
  },
  {
    industry: "Wholesale trade",
    name: "Trailer and other motor vehicle wholesaling"
  },
  {
    industry: "Wholesale trade",
    name: "Wool wholesaling"
  },
]
