<template>
  <div>
    <div class="anchor text-link" @click="handleClickToGenerateLink">{{ readableName() }}</div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "S3FileGenerator",
  props: [
    "document"
  ],
  data() {
    return {
      temporaryLinkUri: ""
    }
  },
  methods: {
    ...mapActions('s3ServiceModule', [
      'fetchFile'
    ]),

    async handleClickToGenerateLink() {
      try {
        const s3Data = await this.fetchFile(this.document);
        if (s3Data && s3Data.hasOwnProperty('status') && s3Data.status === 200) {
          this.temporaryLinkUri = s3Data.data.data.url;
          window.open(this.temporaryLinkUri, "_blank");
        } else {

        }
      }
      catch (e) {

        }
    },

    readableName() {
      if (!this.document) {
        return;
      }

      const newName = this.document.split("/").pop();
      return newName.split("-").splice(1, newName.split('-').length).join("-");
    }
  }
}
</script>