export default [
  {
    industry: "Other services",
    name: "Automotive body, paint and interior repair"
  },
  {
    industry: "Other services",
    name: "Automotive electrical services"
  },
  {
    industry: "Other services",
    name: "Brothel keeping and prostitution services"
  },
  {
    industry: "Other services",
    name: "Business and professional association services"
  },
  {
    industry: "Other services",
    name: "Clothing and footwear repair"
  },
  {
    industry: "Other services",
    name: "Diet and weight reduction centre operation"
  },
  {
    industry: "Other services",
    name: "Domestic appliance and repair and maintenance"
  },
  {
    industry: "Other services",
    name: "Electronic (except domestic appliance) and precision equipment repair and maintenance"
  },
  {
    industry: "Other services",
    name: "Funeral, crematorium and cemetery services"
  },
  {
    industry: "Other services",
    name: "Hairdressing and beauty services"
  },
  {
    industry: "Other services",
    name: "Labour association services"
  },
  {
    industry: "Other services",
    name: "Laundry and dry-cleaning services"
  },
  {
    industry: "Other services",
    name: "Other automotive repair and maintenance"
  },
  {
    industry: "Other services",
    name: "Other interest group services nec"
  },
  {
    industry: "Other services",
    name: "Other machinery and equipment repair maintenance"
  },
  {
    industry: "Other services",
    name: "Other personal services nec"
  },
  {
    industry: "Other services",
    name: "Other repair and maintenance nec"
  },
  {
    industry: "Other services",
    name: "Parking services"
  },
  {
    industry: "Other services",
    name: "Photographic film processing"
  },
  {
    industry: "Other services",
    name: "Private households employing staff"
  },
  {
    industry: "Other services",
    name: "Religious services"
  },
  {
    industry: "Other services",
    name: "Undifferentiated goods-producing activities of private households for own use"
  },
  {
    industry: "Other services",
    name: "Undifferentiated service-producing activities of private households for own use"
  },
]
