<template>
  <div>
    <template v-for="(owner, key) in beneficiaryOwners">
      <div class="flex mb-8" :key="key" :id="'bowner' + key">
        <div class="w-full">
          <h3 class="text-base font-normal">
            Beneficial owner {{ parseInt(key) + 1 }}
          </h3>
          <hr class="line-hr mt-4 mb-8" />
          <div :key="key" class="mb-4">
            <div>
              <div class="flex flex-wrap">
                <div class="input-grp">
                  <label class="vs-input--label p-0 w-full"
                    >First Name <span class="mid-blue">*</span></label
                  >
                  <vs-input
                    v-model="owner.firstName"
                    :name="`Owner First Name[${key}]`"
                    data-vv-as="First name"
                    class="w-full"
                    v-validate="'required'"
                    @keyup="checkIfCardIsComplete(owner,key)"
                  />
                  <span
                    v-show="errors.has(`Owner First Name[${key}]`)"
                    class="text-danger text-sm"
                    >{{ errors.first(`Owner First Name[${key}]`) }}</span
                  >
                </div>

                <div class="input-grp">
                  <label class="vs-input--label p-0 w-full">Middle Name</label>
                  <vs-input
                    v-model="owner.middleName"
                    :name="`Owner Middle Name[${key}]`"
                    data-vv-as="Middle name"
                    class="w-full surcharge-top"
                    @keyup="checkIfCardIsComplete"
                  />
                  <span
                    v-show="errors.has(`Owner Middle Name[${key}]`)"
                    class="text-danger text-sm"
                    >{{ errors.first(`Owner Middle Name[${key}]`) }}</span
                  >
                </div>
              </div>

              <div class="input-grp mt-8">
                <label class="vs-input--label p-0 w-full"
                  >Last Name <span class="mid-blue">*</span></label
                >
                <vs-input
                  v-model="owner.lastName"
                  :name="`Owner Last Name[${key}]`"
                  data-vv-as="Last name"
                  class="w-full"
                  v-validate="'required'"
                  @keyup="checkIfCardIsComplete(owner,key)"
                />
                <span
                  v-show="errors.has(`Owner Last Name[${key}]`)"
                  class="text-danger text-sm"
                >
                  {{ errors.first(`Owner Last Name[${key}]`) }}
                </span>
              </div>

              <div class="flex mt-8 flex-wrap">
                <div class="input-grp">
                  <label class="vs-input--label p-0 w-full"
                    >Mobile Phone Number <span class="mid-blue">*</span></label
                  >
                  <vs-input
                    :name="`Owner Mobile[${key}]`"
                    v-model="owner.mobileNumber"
                    data-vv-as="Mobile phone number"
                    v-mask="'#### ### ###'"
                    v-validate="{
                      required: true,
                      regex: /^04\(?\d{2}\)?[\s.-]\d{3}[\s.-]\d{3}$/,
                    }"
                    class="w-full"
                    @keyup="checkIfCardIsComplete"
                  />
                  <span
                    v-show="errors.has(`Owner Mobile[${key}]`)"
                    class="text-danger text-sm"
                  >
                    {{ errors.first(`Owner Mobile[${key}]`) }}
                  </span>
                </div>

                <div class="input-grp">
                  <label class="vs-input--label p-0 w-full"
                    >Email Address <span class="mid-blue">*</span></label
                  >
                  <vs-input
                    :name="`Owner Email[${key}]`"
                    v-model="owner.email"
                    v-validate="'required|email'"
                    data-vv-as="Email address"
                    class="w-full"
                    @keyup="checkIfCardIsComplete"
                  />

                  <span
                    v-show="errors.has(`Owner Email[${key}]`)"
                    class="text-danger text-sm"
                  >
                    {{ errors.first(`Owner Email[${key}]`) }}</span
                  >
                </div>
              </div>

              <div class="w-full mt-8">
                <vs-checkbox
                  class="mb-4"
                  v-model="owner.isSameAsAuthorisedSignature"
                  @change="checkAsAuthorisedSignature(owner)"
                  :disabled="!checkIfCardSectionIsComplete(key)"
                >
                  <h6 class="font-light text-base">
                    This person is also an authorised signatory
                  </h6>
                </vs-checkbox>
                <!--                <vs-checkbox
                  class="mb-4"
                  v-model="owner.isSameAsPrimaryContact"
                  @change="checkAsPrimaryContact(owner)"
                  :disabled="ifOnePrimaryContactIsSelected(owner)||!checkIfCardSectionIsComplete(key)"
                >
                <h6 class="font-light text-base">This person is also the primary contact person</h6>
                </vs-checkbox>-->
              </div>
            </div>
          </div>
        </div>

        <!--
          <vs-icon
            icon="delete_outline"
            size="small"
            class="float-right"
            @click="deleteSelectedBeneficialOwner(key, owner)"
            v-show="beneficiaryOwners.length>1"
          ></vs-icon> -->
        <div>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            @click="deleteSelectedBeneficialOwner(key, owner)"
            v-show="beneficiaryOwners.length > 0"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.66675 4.99992C1.66675 4.53968 2.03984 4.16658 2.50008 4.16658H17.5001C17.9603 4.16658 18.3334 4.53968 18.3334 4.99992C18.3334 5.46016 17.9603 5.83325 17.5001 5.83325H2.50008C2.03984 5.83325 1.66675 5.46016 1.66675 4.99992Z"
              fill="#757575"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.33342 2.49992C8.1124 2.49992 7.90044 2.58772 7.74416 2.744C7.58788 2.90028 7.50008 3.11224 7.50008 3.33325V4.16658H12.5001V3.33325C12.5001 3.11224 12.4123 2.90028 12.256 2.744C12.0997 2.58772 11.8878 2.49992 11.6667 2.49992H8.33342ZM14.1667 4.16658V3.33325C14.1667 2.67021 13.9034 2.03433 13.4345 1.56548C12.9657 1.09664 12.3298 0.833252 11.6667 0.833252H8.33342C7.67037 0.833252 7.03449 1.09664 6.56565 1.56548C6.09681 2.03433 5.83342 2.67021 5.83342 3.33325V4.16658H4.16675C3.70651 4.16658 3.33341 4.53968 3.33341 4.99992V16.6666C3.33341 17.3296 3.59681 17.9655 4.06565 18.4344C4.53449 18.9032 5.17037 19.1666 5.83342 19.1666H14.1667C14.8298 19.1666 15.4657 18.9032 15.9345 18.4344C16.4034 17.9655 16.6667 17.3296 16.6667 16.6666V4.99992C16.6667 4.53968 16.2937 4.16658 15.8334 4.16658H14.1667ZM5.00008 5.83325V16.6666C5.00008 16.8876 5.08788 17.0996 5.24416 17.2558C5.40044 17.4121 5.6124 17.4999 5.83342 17.4999H14.1667C14.3878 17.4999 14.5997 17.4121 14.756 17.2558C14.9123 17.0996 15.0001 16.8876 15.0001 16.6666V5.83325H5.00008Z"
              fill="#757575"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.33342 8.33325C8.79365 8.33325 9.16675 8.70635 9.16675 9.16658V14.1666C9.16675 14.6268 8.79365 14.9999 8.33342 14.9999C7.87318 14.9999 7.50008 14.6268 7.50008 14.1666V9.16658C7.50008 8.70635 7.87318 8.33325 8.33342 8.33325Z"
              fill="#757575"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.6667 8.33325C12.127 8.33325 12.5001 8.70635 12.5001 9.16658V14.1666C12.5001 14.6268 12.127 14.9999 11.6667 14.9999C11.2065 14.9999 10.8334 14.6268 10.8334 14.1666V9.16658C10.8334 8.70635 11.2065 8.33325 11.6667 8.33325Z"
              fill="#757575"
            />
          </svg>
        </div>
      </div>
    </template>

    <a class="text-base mid-blue flex items-center" @click="addBeneficialOwner"
      ><span class="pr-3">
        <plus-icon size="1.5x" class="button-icon mid-blue"></plus-icon>
      </span>
      <span class="text-base mid-blue underline">Add Beneficial Owner</span>
    </a>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import { EventBus } from "../../../../event-bus";
import { PlusIcon } from "vue-feather-icons";

export default {
  components: {
    PlusIcon,
  },
  name: "BeneficiaryOwner",
  directives: { mask },
  props: {
    beneficiaryOwners: { type: Array },
    isComplete: { type: Object },
  },
  data() {
    return {
      payload: [],
      hasBeenCompleted: {},
    };
  },
  created() {
    this.payload = this.beneficiaryOwners;
    this.hasBeenCompleted = this.isComplete;
    this.checkIfCardIsComplete();
    EventBus.$on("unCheckInBusinessOwnerForAuthorisedSignature", (data) => {
      const searchedIndex = this.beneficiaryOwners.findIndex(
        (row) =>
          row.email === data.email && row.mobileNumber === data.mobileNumber
      );
      if (searchedIndex !== -1)
        this.beneficiaryOwners[
          searchedIndex
        ].isSameAsAuthorisedSignature = false;
      this.checkIfCardIsComplete();
    });
    if (Array.isArray(this.beneficiaryOwners)) {
      for (const owner of this.beneficiaryOwners) {
        if (
          owner.hasOwnProperty("isSameAsAuthorisedSignature") &&
          owner.isSameAsAuthorisedSignature
        ) {
          const waitMeFor = setTimeout(() => {
            this.checkAsAuthorisedSignature(owner);
            clearTimeout(waitMeFor);
          }, 200);
        }
      }
    }
  },
  methods: {
    changeMessage(data, removeString, addString) {
      return data ? data.replace(removeString, addString) : "";
    },

    addBeneficialOwner() {
      this.beneficiaryOwners.push({
        firstName: "",
        middleName: "",
        lastName: "",
        mobileNumber: "",
        email: "",
      });
      this.checkIfCardIsComplete();
      this.$emit("handleCardHeight");
    },

    deleteSelectedBeneficialOwner(key, owner) {
      EventBus.$emit("removeBeneficialOwnerFromAuthorisedSignature", owner);
      this.beneficiaryOwners.splice(key, 1);
      this.checkIfCardIsComplete();
      this.$emit("handleCardHeight");
    },

    checkIfCardSectionIsComplete(index) {
      return (
        this.beneficiaryOwners[index].firstName.length > 0 &&
        this.beneficiaryOwners[index].lastName.length > 0 &&
        this.beneficiaryOwners[index].mobileNumber.length > 0 &&
        !this.errors.has(`Owner Mobile[${index}]`) &&
        this.beneficiaryOwners[index].email.length > 0 &&
        !this.errors.has(`Owner Email[${index}]`)
      );
    },

    checkIfCardIsComplete(owner = {}, ownerIndex = -1) {
      let statuses = [];
      let status = true;

      for (let index = 0; index < this.beneficiaryOwners.length; index++) {
        let {firstName, lastName, mobileNumber, email} = this.beneficiaryOwners[index]

          let isUnique = true
          let isMobileUnique = true
          let isEmailUnique = true
          for (let ownerIndex = 0; ownerIndex < this.beneficiaryOwners.length; ownerIndex++) {
            if(ownerIndex != index && firstName && lastName){

              if(`${firstName}${lastName}` == 
              `${this.beneficiaryOwners[ownerIndex].firstName}${this.beneficiaryOwners[ownerIndex].lastName}`){
                  isUnique = false
                  this.errors.add({
                    field: `Owner Last Name[${index}]`,
                    msg: "This name has been already used."
                  })
                  
                  this.errors.add({
                    field: `Owner First Name[${index}]`,
                    msg: "This name has been already used."
                  })
              }
              else if(isUnique){
                this.errors.remove(`Owner Last Name[${index}]`)
                this.errors.remove(`Owner First Name[${index}]`)
              }
            }
            //unique email
            if(ownerIndex != index && email && 
                ( !this.errors.has(`Owner Email[${index}]`) || 
                  this.errors.first(`Owner Email[${index}]`) == "This email has been already used."
                )
              ){

              if(`${email}` == 
              `${this.beneficiaryOwners[ownerIndex].email}`){
                  isEmailUnique = false
                  this.errors.add({
                    field: `Owner Email[${index}]`,
                    msg: "This email has been already used."
                  })
              }
              else if(isEmailUnique){
                this.errors.remove(`Owner Email[${index}]`)
              }
            }
            //unique mobile number
            if(ownerIndex != index && mobileNumber && 
                ( !this.errors.has(`Owner Mobile[${index}]`) || 
                  this.errors.first(`Owner Mobile[${index}]`) == "This number has been already used."
                )){

              if(`${mobileNumber}` == 
              `${this.beneficiaryOwners[ownerIndex].mobileNumber}`){
                  isMobileUnique = false
                  this.errors.add({
                    field: `Owner Mobile[${index}]`,
                    msg: "This number has been already used."
                  })
              }
              else if(isMobileUnique){
                this.errors.remove(`Owner Mobile[${index}]`)
              }
            }
            
          }
        statuses[index] =
          this.beneficiaryOwners[index].firstName.length > 0 &&
          this.beneficiaryOwners[index].lastName.length > 0 &&
          this.beneficiaryOwners[index].mobileNumber.length > 0 &&
          !this.errors.has(`Owner Mobile[${index}]`) &&
          this.beneficiaryOwners[index].email.length > 0 &&
          !this.errors.has(`Owner Email[${index}]`);
      }

      for (let index = 0; index < statuses.length; index++) {
        if (!statuses[index]) {
          status = false;
          break;
        }
      }

      this.hasBeenCompleted.beneficiaryOwnersStatus =
        this.beneficiaryOwners.length === 0 ? true : status;
    },

    validateForm() {
      return this.$validator.validateAll();
    },

    checkAsAuthorisedSignature(owner) {
      if (owner.isSameAsAuthorisedSignature)
        EventBus.$emit("addBeneficialOwnerAsAuthorisedSignature", owner);
      else
        EventBus.$emit("removeBeneficialOwnerFromAuthorisedSignature", owner);
    },

    checkAsPrimaryContact(owner) {
      if (owner.isSameAsPrimaryContact)
        EventBus.$emit("addBeneficialOwnerAsPrimaryContact", owner);
      else EventBus.$emit("removeBeneficialOwnerFromPrimaryContact", owner);
    },

    ifOnePrimaryContactIsSelected(owner) {
      let statuses = [];
      if (this.beneficiaryOwners.length > 0) {
        for (let index = 0; index < this.beneficiaryOwners.length; index++) {
          statuses[index] =
            this.beneficiaryOwners[index].isSameAsPrimaryContact;
        }
        for (const isChecked of statuses) {
          if (isChecked && !owner.isSameAsPrimaryContact) return true;
        }
      }

      return false;
    },
  },
  watch: {
    isFormDirty(val) {
      if (val) {
        this.$emit("checkDirty", this.isFormDirty);
      }
    },
  },
};
</script>
