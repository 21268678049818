export default [
  {
    industry: "Public administration and safety",
    name: "Central government administration"
  },
  {
    industry: "Public administration and safety",
    name: "Correctional and detention services"
  },
  {
    industry: "Public administration and safety",
    name: "Defence"
  },
  {
    industry: "Public administration and safety",
    name: "Domestic government representation"
  },
  {
    industry: "Public administration and safety",
    name: "Fire protection and other emergency services"
  },
  {
    industry: "Public administration and safety",
    name: "Foreign government representation"
  },
  {
    industry: "Public administration and safety",
    name: "Investigation and security services"
  },
  {
    industry: "Public administration and safety",
    name: "Justice"
  },
  {
    industry: "Public administration and safety",
    name: "Local government administration"
  },
  {
    industry: "Public administration and safety",
    name: "Other public order and safety services"
  },
  {
    industry: "Public administration and safety",
    name: "Police services"
  },
  {
    industry: "Public administration and safety",
    name: "Regulatory services"
  },
  {
    industry: "Public administration and safety",
    name: "State government administration"
  },
]
