<template>
  <div class="bank-profile-form">
    <template v-for="(bank, key) in bankAccount">
      <div :key="key" :id="'bankAcc' + key">
        <div>
          <div class="flex items-center mb-2">
            <svg width="24" height="20" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 4V2C10 0.9 9.1 0 8 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H18C19.1 18 20 17.1 20 16V6C20 4.9 19.1 4 18 4H10ZM4 16H2V14H4V16ZM4 12H2V10H4V12ZM4 8H2V6H4V8ZM4 4H2V2H4V4ZM8 16H6V14H8V16ZM8 12H6V10H8V12ZM8 8H6V6H8V8ZM8 4H6V2H8V4ZM17 16H10V14H12V12H10V10H12V8H10V6H17C17.55 6 18 6.45 18 7V15C18 15.55 17.55 16 17 16ZM16 8H14V10H16V8ZM16 12H14V14H16V12Z" fill="#BDBDBD"/>
            </svg>
            <h5 class="text-xl font-normal ml-4">Bank account configuration {{ key + 1 }}</h5>
            <feather-icon class="ml-auto inline mb-0 md:hidden" svgClasses="w-5 h-5" icon="Trash2Icon" v-if="bankAccount.length > 1" @click="deleteBank(bank, key)" />
          </div>
          <hr class="line-hr" />
        </div>

        <div class="pt-6 md:pt-10 mt-1 mb-12">
          <div class="grid grid-cols-12 gap-y-6 md:gap-y-0 md:gap-x-6 lg:gap-x-10">
            <div class="col-span-1 md:col-span-1 lg:col-span-2 hidden md:block md:order-last">
              <feather-icon class="float-right" svgClasses="w-5 h-5" icon="Trash2Icon" v-if="bankAccount.length > 1" @click="deleteBank(bank, key)" />
            </div>
            <div class="col-span-12 md:col-span-5 lg:col-span-4">
              <vs-card class="bankCardSettlement shadow-none mb-0">
                <div slot="header">
                  <div class="items-center">
                    <h5 class="font-normal text-lg md:text-xl">
                      Settlement account
                      <vs-tooltip text="Settlement accounts are used for receiving money from us or your customers. This could be a sales or trust account." position="top" class="icon-tooltip">
                        <span class="info__bank">?</span>
                      </vs-tooltip>
                    </h5>
                  </div>
                </div>
                <div class="onboarding-form-settlement">
                  <div v-if="hasUnUsedBankingAccount(key)" class="flex flex-column mb-6 md:mb-10">
                    <div class="flex items-center">
                      <vs-switch class="" v-model="bank.existingBankAccount" @change="toggleAccountForSettlement(bank, key)" :disabled="bank.sameAsSettlementAccount"/>
                      <span class="text-base font-light ml-4 mb-0 leading-tight">Use an existing bank account</span>
                    </div>
                    <div v-if="bank.existingBankAccount" class="input-grp mt-8">
                      <div class="w-full">
                        <label class="vs-input--label w-full p-0" :class="errors.has(`selectSettlement[${key}]`) ? 'text-danger' : ''">Select account</label>
                        <vs-select
                          v-model="bank.selectedSettlementBank"
                          autocomplete
                          v-validate="'required'"
                          class="w-full rounded"
                          data-vv-as="Entity"
                          :name="`selectSettlement[${key}]`"
                          @change="selectSettlementBank(bank, key)"
                          :disabled="bank.sameAsSettlementAccount"
                        >
                          <vs-select-item v-for="(settlement, index) in getValidBanks(bank)" :key="index" :text="'accountName' in settlement ? settlement.accountName : ''" :value="'accountName' in settlement ? settlement.accountName : ''"></vs-select-item>
                        </vs-select>
                        <span v-show="errors.has(`selectSettlement[${key}]`)" lass="text-danger text-sm">
                          {{ errors.first(`selectSettlement[${key}]`) }}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div v-show="!bank.existingBankAccount || dataSelectedFromListing(key)" class="input-grp w-full mb-6 md:mb-10 mr-0">
                    <div class="">
                      <label class="vs-input--label w-full p-0 text-dark-blue" :class="errors.has(`accountname[${key}]`) ? 'text-danger' : ''">Account name</label>
                      <vs-input
                        class="w-full"
                        :class="( bank.sameAsSettlementAccount || bank.existingBankAccount ) ? 'readonly' : 'normal'"
                        v-model="bank.accountName"
                        :name="`accountname[${key}]`"
                        @keyup="handleChange(key, 'accountName')"
                        @change="handleChange(key, 'accountName')"
                        data-vv-as="Account name"
                        v-validate="'required'"
                        :readonly="(bank.sameAsSettlementAccount || bank.existingBankAccount) ? 'readonly' : null"
                      />

                      <span v-show="errors.has(`accountname[${key}]`)" class="text-danger text-sm">
                        {{ errors.first(`accountname[${key}]`) }}
                      </span>
                    </div>
                  </div>

                  <div v-show="!bank.existingBankAccount || dataSelectedFromListing(key)" class="mb-6 md:mb-10">
                    <div class="grid grid-cols-7 gap-y-6 md:gap-y-0 sm:gap-x-8">
                      <div class="col-span-7 sm:col-span-3">
                        <label class="vs-input--label w-full p-0" :class="errors.has(`bsb[${key}]`) ? 'text-danger' : ''">
                          BSB
                        </label>
                        <the-mask
                          :class="( bank.sameAsSettlementAccount || bank.existingBankAccount ) ? 'readonly vs-inputx vs-input--input large w-full' : 'normal vs-inputx vs-input--input large w-full'"
                          :mask="['### ###']"
                          v-model="bank.bsb"
                          masked
                          data-vv-as="BSB"
                          ref="bsb"
                          :name="`bsb[${key}]`"
                          @keyup.native="handleChange(key, 'bsb')"
                          @change.native="handleChange(key, 'bsb')"
                          v-validate="'required|min:7'"
                          :readonly="(bank.sameAsSettlementAccount || bank.existingBankAccount) ? 'readonly' : null"
                        />
                        <span v-show="errors.has(`bsb[${key}]`)" class="text-danger text-sm">
                          {{ showBSBError(errors.items)[0] }}
                        </span>
                      </div>

                      <div class="col-span-7 sm:col-span-4">
                        <label class="vs-input--label w-full p-0" :class="errors.has(`accno[${key}]`) ? 'text-danger' : ''">Account number</label>
                        <vs-input
                          class="w-full"
                          :class="( bank.sameAsSettlementAccount || bank.existingBankAccount ) ? 'readonly' : 'normal'"
                          v-model="bank.accountNumber"
                          v-validate="'required|numeric'"
                          :name="`accno[${key}]`"
                          data-vv-as="Account number"
                          @keyup="handleChange(key, 'accountNumber')"
                          @change="handleChange(key, 'accountNumber')"
                          :readonly="(bank.sameAsSettlementAccount || bank.existingBankAccount) ? 'readonly' : null"
                        />
                        <span v-show="errors.has(`accno[${key}]`)" class="text-danger text-sm">
                          {{ errors.first(`accno[${key}]`) }}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div v-show="!bank.existingBankAccount || dataSelectedFromListing(key)" class="input-grp w-full mr-0 mb-6 md:mb-10">
                    <label class="vs-input--label w-full p-0 mb-0" :class="errors.has(`file[${key}]`) ? 'text-danger' : ''">Bank statement</label>
                    <div v-if="bank.hasFileUploaded || (typeof bank.bankStatement === 'string' && bank.bankStatement.length > 0)" class="w-full flex justify-between mt-4 items-center">
                      <a href="#" class="text-base text-link">
                        {{
                          typeof bank.bankStatement === "string"
                            ? readableName(bank.bankStatement)
                            : bankAccount[key].bankStatement !== undefined
                              ? bankAccount[key].bankStatement.name
                              : null
                        }}
                      </a>
                      <a
                        v-if="(
                          bank.hasFileUploaded
                          || (typeof bank.bankStatement === 'string' && bank.bankStatement.length > 0)
                        ) && !bank.sameAsSettlementAccount"
                        @click="removeFiles(bank, key)"
                      >
                        <feather-icon class="" svgClasses="w-5 h-5" icon="Trash2Icon" />
                      </a>
                    </div>
                    <input
                      type="file"
                      class="hidden"
                      :name="`file[${key}]`"
                      accept=".pdf"
                      ref="uploadImgInput"
                      @change="updateCurrImg($event, key)"
                      v-validate="{ size: maxFileSize * 1024  }"
                    />
                    <input type="text" class="hidden" name="hasFileUploaded" v-model="bank.hasFileUploaded"/>
                    <vs-button
                      v-if="!(bank.hasFileUploaded ||
                        (typeof bank.bankStatement === 'string' && bank.bankStatement.length > 0))
                      "
                      class="only-border-btn mt-2"
                      size="small"
                      style="width: 126px"
                      v-round
                      @click="uploadImgInput(key)"
                      v-show="!(bank.sameAsSettlementAccount || bank.existingBankAccount)"
                    >Upload file
                    </vs-button>

                    <span v-show="errors.has(`file[${key}]`)" class="text-danger text-sm">
                      {{ changeMessage(errors.first(`file[${key}]`), `file[${key}]`, 'bank statement') }}
                    </span>
                  </div>

                  <div v-show="!bank.existingBankAccount || dataSelectedFromListing(key)" class="input grp w-full mr-0">
                    <label class="vs-input--label w-full p-0" :class="errors.has(`entity[${key}]`) ? 'text-danger' : ''">
                      Which company owns the bank account?
                    </label>
                    <vs-select
                      v-model="bank.entity"
                      autocomplete
                      v-validate="'required'"
                      class="w-full rounded"
                      data-vv-as="Entity"
                      :name="`entity[${key}]`"
                      :disabled="bank.sameAsSettlementAccount"
                      @change="handleChange(key,'entity')"
                    >
                      <vs-select-item
                        v-for="(entity, index) in entityList"
                        :key="index"
                        :text="entity.entityType == 'Company' ? entity.companyName || '' : entity.fullName || ''"
                        :value="entity.entityId"
                      ></vs-select-item>
                    </vs-select>

                    <span v-show="errors.has(`entity[${key}]`)" class="text-danger text-sm">
                      {{ errors.first(`entity[${key}]`) }}
                    </span>
                  </div>
                </div>
              </vs-card>
            </div>
            <div class="col-span-12 md:col-span-6 lg:col-span-6">
              <vs-card class="bankCardBilling shadow-none mb-0">
                <div slot="header">
                  <div class="flex row justify-between">
                    <h5 class="font-normal text-lg md:text-xl">
                      Billing account
                      <vs-tooltip text="Billing accounts are used for paying fees to us. This could be a general or operations account that supports direct debiting." position="top" class="icon-tooltip">
                        <span class="info__bank">?</span>
                      </vs-tooltip>
                    </h5>
                    <div class="flex row">
                      <span class="font-light text-base text-gray mb-0">Same as settlement account</span>
                      <vs-checkbox class="mr-0 ml-4" v-model="bank.sameAsSettlementAccount" @change="handleBankAsSettlementToggle(bank, key)" :disabled="isSameAsSettlementDisabled(key)"></vs-checkbox>
                    </div>
                  </div>
                </div>
                <div class="onboarding-form-billing">
                  <div class="grid grid-cols-12">
                    <div class="col-span-12 md:col-span-7">
                      <div v-if="hasUnUsedBankingAccountForBilling(key)" class="flex flex-column mb-6 md:mb-10">
                        <div class="flex items-center">
                          <vs-switch class="" v-model="bank.existingBankAccountForBilling" @change="toggleAccountForBilling(bank, key)"/>
                          <span class="text-base font-light ml-4 mb-0 leading-tight">Use an existing bank account</span>
                        </div>
                        <div v-if="bank.existingBankAccountForBilling" class="input-grp mt-8">
                          <div class="w-full">
                            <label class="vs-input--label w-full p-0" :class="errors.has(`selectSettlementForBilling[${key}]`) ? 'text-danger' : ''">Select account</label>
                            <vs-select
                              v-model="bank.selectedBillingBank"
                              autocomplete
                              v-validate="'required'"
                              class="w-full rounded"
                              data-vv-as="Entity"
                              :name="`selectSettlementForBilling[${key}]`"
                              @change="selectBillingBank(bank, key)"
                            >
                              <vs-select-item
                                v-for="(settlement, index) in getValidBillingBanks()"
                                :key="index"
                                :text="'accountName' in settlement ? settlement.accountName : ''"
                                :value="'accountName' in settlement ? settlement.accountName : ''"
                              ></vs-select-item>
                            </vs-select>
                            <span v-show="errors.has(`selectSettlementForBilling[${key}]`)" class="text-danger text-sm">
                              {{ errors.first(`selectSettlementForBilling[${key}]`) }}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div v-show="!bank.existingBankAccountForBilling || dataSelectedFromListingForBanking(key)" class="input-grp w-full mb-6 md:mb-10 mr-0">
                        <div class="w-full">
                          <label class="vs-input--label w-full p-0" :class="errors.has(`accountnameforbilling[${key}]`) ? 'text-danger' : ''">Account name</label>
                          <vs-input
                            class="w-full"
                            :class="( bank.sameAsSettlementAccount || bank.existingBankAccountForBilling ) ? 'readonly' : 'normal'"
                            v-model="bank.accountNameForBilling"
                            :name="`accountnameforbilling[${key}]`"
                            @keyup="handleChange(key, 'accountNameForBilling')"
                            @change="handleChange(key, 'accountNameForBilling')"
                            data-vv-as="Account name"
                            v-validate="'required'"
                            :readonly="( bank.sameAsSettlementAccount || bank.existingBankAccountForBilling ) ? 'readonly' : null"
                          />

                          <span v-show="errors.has(`accountnameforbilling[${key}]`)" class="text-danger text-sm">
                            {{ errors.first(`accountnameforbilling[${key}]`) }}
                          </span>
                        </div>
                      </div>

                      <div v-show="!bank.existingBankAccountForBilling || dataSelectedFromListingForBanking(key)" class="mb-6 md:mb-10">
                        <div class="grid grid-cols-7 gap-y-6 md:gap-y-0 sm:gap-x-8">
                          <div class="col-span-7 sm:col-span-3" :class="bank.sameAsSettlementAccount ? 'readonly' : 'normal'">
                            <label class="vs-input--label w-full p-0" :class="errors.has(`bsbforbilling[${key}]`) ? 'text-danger' : ''">BSB</label>
                            <the-mask
                              :class="( bank.sameAsSettlementAccount || bank.existingBankAccountForBilling ) ? 'readonly vs-inputx vs-input--input large w-full' : 'normal vs-inputx vs-input--input large w-full'"
                              :mask="['### ###']"
                              v-model="bank.bsbForBilling"
                              masked
                              data-vv-as="BSB"
                              ref="bsb"
                              :name="`bsbforbilling[${key}]`"
                              @keyup.native="handleChange(key, 'bsbForBilling')"
                              @change.native="handleChange(key, 'bsbForBilling')"
                              v-validate="'required|min:7'"
                              :readonly="( bank.sameAsSettlementAccount || bank.existingBankAccountForBilling ) ? 'readonly' : null"
                            />
                            <span v-show="errors.has(`bsbforbilling[${key}]`)" class="text-danger text-sm">
                              {{ showBSBError(errors.items)[0] }}
                            </span>
                          </div>

                          <div class="col-span-7 sm:col-span-4">
                            <label class="vs-input--label w-full p-0" :class="errors.has(`accnoforbilling[${key}]`) ? 'text-danger' : ''">Account number</label>
                            <vs-input
                              class="w-full"
                              :class="( bank.sameAsSettlementAccount || bank.existingBankAccountForBilling ) ? 'readonly' : 'normal'"
                              v-model="bank.accountNumberForBilling"
                              v-validate="'required|numeric'"
                              :name="`accnoforbilling[${key}]`"
                              data-vv-as="Account number"
                              @keyup="handleChange(key, 'accountNumberForBilling')"
                              @change="handleChange(key, 'accountNumberForBilling')"
                              :readonly="( bank.sameAsSettlementAccount || bank.existingBankAccountForBilling ) ? 'readonly' : null"
                            />

                            <span v-show="errors.has(`accnoforbilling[${key}]`)" class="text-danger text-sm">
                              {{ errors.first(`accnoforbilling[${key}]`) }}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div v-show="!bank.existingBankAccountForBilling || dataSelectedFromListingForBanking(key)" class="input-grp w-full mr-0 mb-6 md:mb-10">
                        <label class="vs-input--label w-full p-0 mb-0" :class="errors.has(`fileforbilling[${key}]`) ? 'text-danger' : ''">Bank statement</label>
                        <div v-if="bank.hasFileUploadedForBilling || (typeof bank.bankStatementForBilling === 'string' && bank.bankStatementForBilling.length > 0)" class="w-full flex justify-between mt-4 items-center">
                          <a href="#" class="text-base text-link">
                            {{
                              typeof bank.bankStatementForBilling === "string"
                                ? readableName(bank.bankStatementForBilling)
                                : bankAccount[key].bankStatementForBilling !== undefined
                                  ? bankAccount[key].bankStatementForBilling.name
                                  : null
                            }}
                          </a>
                          <a
                            :class="bank.sameAsSettlementAccount ? 'readonly' : 'normal'"
                            v-if="(
                              bank.hasFileUploadedForBilling
                              || (
                                typeof bank.bankStatementForBilling === 'string'
                                && bank.bankStatementForBilling.length > 0
                                )
                            ) && !bank.sameAsSettlementAccount
                             && !bank.existingBankAccountForBilling"
                            @click="removeFilesForBilling(bank, key)"
                          >
                            <feather-icon class="" svgClasses="w-5 h-5" icon="Trash2Icon" />
                          </a>
                        </div>
                        <input
                          type="file"
                          class="hidden"
                          :name="`fileforbilling[${key}]`"
                          accept=".pdf"
                          ref="uploadImgInputForBilling"
                          @change="updateCurrImgForBilling($event, key)"
                          v-validate="{ size: maxFileSize * 1024  }"
                        />
                        <input
                          type="text"
                          class="hidden"
                          name="hasFileUploadedForBilling"
                          v-model="bank.hasFileUploadedForBilling"
                        />
                        <vs-button
                          v-if="!(bank.hasFileUploadedForBilling || (typeof bank.bankStatementForBilling === 'string' && bank.bankStatementForBilling.length > 0))"
                          class="only-border-btn mt-2"
                          size="small"
                          style="width: 126px"
                          v-round
                          @click="uploadImgInputForBilling(key)"
                          v-show="!(bank.sameAsSettlementAccount || bank.existingBankAccountForBilling)"
                        >Upload file
                        </vs-button>

                        <span v-show="errors.has(`fileforbilling[${key}]`)" class="text-danger text-sm">
                          {{ changeMessage(errors.first(`fileforbilling[${key}]`), `fileforbilling[${key}]`, 'bank statement') }}
                        </span>
                      </div>
                      <div v-show="!bank.existingBankAccountForBilling || dataSelectedFromListingForBanking(key)" class="input grp w-full mr-0">
                    <label class="vs-input--label w-full p-0" :class="errors.has(`entityforbilling[${key}]`) ? 'text-danger' : ''">Which entity owns this bank account?</label>
                    <p class="entity-text" v-if="bank.sameAsSettlementAccount">{{ getEntity(bank.entityForBilling) }}</p>
                    <vs-select
                      v-else
                      v-model="bank.entityForBilling"
                      autocomplete
                      v-validate="'required'"
                      class="w-full rounded"
                      data-vv-as="Entity"
                      :name="`entityforbilling[${key}]`"
                      @change="handleChange(key,'entityForBilling')"
                    >
                      <vs-select-item
                        v-for="(entity, index) in entityList"
                        :key="index"
                        :text="entity.entityType == 'Company' ? entity.companyName || '' : entity.fullName || ''"
                        :value="entity.entityId"
                      ></vs-select-item>
                    </vs-select>

                    <span v-show="errors.has(`entityforbilling[${key}]`)" class="text-danger text-sm">
                      {{ errors.first(`entityforbilling[${key}]`) }}
                    </span>
                  </div>
                    </div>
                  </div>
                </div>
              </vs-card>
            </div>

          </div>
        </div>
      </div>
    </template>
    <div class="w-full">
      <vs-button class="medium text-white" @click="addBank" v-round><span class="text-sm flex item-center mt-1"><plus-icon size="1.5x" class="button-icon text-white mr-2"></plus-icon> Add configuration</span></vs-button>
    </div>
  </div>
</template>

<script>
import { TheMask } from "vue-the-mask";
import { Validator } from "vee-validate";
import { PlusIcon } from "vue-feather-icons";
import { mapActions } from "vuex";
import { EventBus } from "../../../../event-bus";
import { sentryLog } from "../../../../helper/sentryLog";

const dictionary = {
  en: {
    attributes: {},
  },
};

Validator.localize(dictionary);

export default {
  // name:"BankAccount",

  components: {
    TheMask,
    PlusIcon,
  },

  props: {
    bankAccount: { type: Array },
    isComplete: { type: Object },
    entityList: { type: Array },
    applicationStatus: { type: String },
  },

  data() {
    return {
      files: [],
      bankSet: {
        accountName: "",
        bsb: "",
        accountNumber: "",
        bankStatement: "",
        isUsedForSettlement: false,
        isUsedForBilling: false,
        hasFileUploaded: false,
        uniqueIdForTill: "",
        accountNameForBilling: "",
        bsbForBilling: "",
        accountNumberForBilling: "",
        bankStatementForBilling: "",
        existingBankAccountForBilling: false,
        existingBankAccount: false,
        sameAsSettlementAccount: false,
        hasFileUploadedForBilling: false,
        selectedSettlementBank: "",
        selectedBillingBank: "",
        isUsed: false,
        entity: "",
        entityForBilling: ""
      },
      hasBeenCompleted: {},
      onboardingRequestId:"",
      forSettlements: [],
      forBillings: [],
      payload: [],
      maxFileSize: process.env.VUE_APP_FILE_MAX_SIZE_MB,
    };
  },

  methods: {
    ...mapActions("onboardMerchant", [
      "removeBank",
      "deleteBankStatement"
    ]),

    isSameAsSettlementDisabled(index) {
      const bankNameState = this.bankAccount[index].accountName && this.bankAccount[index].accountName.length > 0;
      const bankBsbState = this.bankAccount[index].bsb && this.bankAccount[index].bsb.length > 0 && !this.errors.has(`bsb[${index}]`);
      const bankNumberState = this.bankAccount[index].accountNumber && this.bankAccount[index].accountNumber.toString().length > 0;
      let bankStatementState = false;

      if ( typeof this.bankAccount[index].bankStatement === "string") {
        bankStatementState = this.bankAccount[index].bankStatement.length > 0;
      }

      if ( typeof this.bankAccount[index].bankStatement === "object") {
        bankStatementState = true;
      }

      const bankEntityState = this.bankAccount[index].entity && this.bankAccount[index].entity.length > 0 && !this.errors.has(`entity[${index}]`);

      return !bankNameState || !bankNumberState || !bankBsbState || !bankStatementState || !bankEntityState;
    },

    showBSBError(errorsArray) {
      let error = errorsArray.map((element) => {
        let firstChar = element.field.charAt(0);
        let secondChar = element.field.charAt(1);

        if (element.rule == `min` && firstChar == `b` && secondChar == `s`) {
          let message = `The BSB field must be at least 6 characters`;
          return message;
        }

        if (
          element.rule == `required` &&
          firstChar == `b` &&
          secondChar == `s`
        ) {
          let message = `The BSB field is required`;
          return message;
        }
      });

      let filteredError = [];

      if (error) {
        filteredError = error.filter((elem) => {
          return elem !== undefined;
        });
      }

      return filteredError;
    },

    async removeBankStatement(key, type) {
      const bankdetailsKey = {};
      bankdetailsKey.bankdetail = this.bankAccount[key];
      bankdetailsKey.onboardingRequestId = this.onboardingRequestId;
      bankdetailsKey.type = type;
      await this.deleteBankStatement(bankdetailsKey).then((response) => {
        return true;
      }).catch((ex) => {
        sentryLog(ex, { message: "Unable to delete bank statement" });
      })
    },

    removeFiles(bank, index) {
      this.bankAccount[index].bankStatement = "";
      this.bankAccount[index].hasFileUploaded = false;
      this.$refs.uploadImgInput[index].value = null;
      this.errors.add({
        field: `file[${index}]`,
        msg: 'The Bank statement field is mandatory'
      });

      this.removeBankStatement(index, 'settlement');

      if (this.errors.has(`file[${index}]`)) {
        this.errors.remove(`file[${index}]`);
      }
      this.checkIfCardIsComplete();
    },

    removeFilesForBilling(bank, index) {
      this.bankAccount[index].bankStatementForBilling = "";
      this.bankAccount[index].hasFileUploadedForBilling = false;
      this.$refs.uploadImgInputForBilling[index].value = null;
      this.errors.add({
        field: `fileforbilling[${index}]`,
        msg: 'The Bank statement field is mandatory'
      });

      this.removeBankStatement(index, 'billing');

      if (this.errors.has(`fileforbilling[${index}]`)) {
        this.errors.remove(`fileforbilling[${index}]`);
      }
      this.checkIfCardIsComplete();
    },

    uploadImgInput(key) {
      this.$refs.uploadImgInput[key].click();
    },

    uploadImgInputForBilling(key) {
      this.$refs.uploadImgInputForBilling[key].click();
    },

    isValidBankSet(bank, index) {
      return (bank.bsb.length > 0 && !this.errors.has(`bsb[${index}]`))
        && ("bsbForBilling" in bank && bank.bsbForBilling.length > 0 && !this.errors.has(`bsbforbilling[${index}]`))
        && bank.accountName.length > 0
        && ("accountNameForBilling" in bank && bank.accountNameForBilling.length > 0)
        && (bank.accountNumber !== "" && !this.errors.has(`accno[${index}]`))
        && ("accountNumberForBilling" in bank && bank.accountNumberForBilling !== "" && !this.errors.has(`accnoforbilling[${index}]`))
        && ((typeof bank.bankStatement === "string" && bank.bankStatement.length > 0)
          || bank.hasFileUploaded === true
        )
        && ( "bankStatementForBilling" in bank
          && ((typeof bank.bankStatementForBilling === "string" && bank.bankStatementForBilling.length > 0)
            || bank.hasFileUploadedForBilling === true)
        );
    },

    addUniqueBillingAccount() {
      if (this.bankAccount.length === 0) {
        return false;
      }

      this.forSettlements = [];
      this.forBillings = [];

      for ( let idx=0; this.bankAccount.length > idx; idx++ ) {
        if ( this.isValidBankSet(this.bankAccount[idx], idx)
          && this.bankAccount[idx].accountName !== this.bankAccount[idx].accountNameForBilling
          && ( !this.forSettlements.map(row => row.accountName).includes(this.bankAccount[idx].accountNameForBilling) )
        ) {
          this.forSettlements.push({
            accountName: this.bankAccount[idx].accountNameForBilling,
            accountNumber: this.bankAccount[idx].accountNumberForBilling,
            bsb: this.bankAccount[idx].bsbForBilling,
            bankStatement: this.bankAccount[idx].bankStatementForBilling,
            hasFileUploaded: this.bankAccount[idx].hasFileUploadedForBilling,
            entity: this.bankAccount[idx].entityForBilling,
            isUsed: this.bankAccount.map(bank => (bank.accountName)).includes(this.bankAccount[idx].accountNameForBilling)
          })
        }

        if ( this.isValidBankSet(this.bankAccount[idx], idx) && !this.forBillings.map(account => (account.accountName)).includes(this.bankAccount[idx].accountName) ) {
          this.forBillings.push({
            accountName: this.bankAccount[idx].accountName,
            accountNumber: this.bankAccount[idx].accountNumber,
            bsb: this.bankAccount[idx].bsb,
            bankStatement: this.bankAccount[idx].bankStatement,
            hasFileUploaded: this.bankAccount[idx].hasFileUploaded,
            entity: this.bankAccount[idx].entity,
            isUsed: this.bankAccount.map(bank => (bank.accountName)).includes(this.bankAccount[idx].accountName)
          })
        }

        if ( this.isValidBankSet(this.bankAccount[idx], idx) && !this.forBillings.map(account => (account.accountName)).includes(this.bankAccount[idx].accountNameForBilling) ) {
          this.forBillings.push({
            accountName: this.bankAccount[idx].accountNameForBilling,
            accountNumber: this.bankAccount[idx].accountNumberForBilling,
            bsb: this.bankAccount[idx].bsbForBilling,
            bankStatement: this.bankAccount[idx].bankStatementForBilling,
            hasFileUploaded: this.bankAccount[idx].hasFileUploadedForBilling,
            entity: this.bankAccount[idx].entityForBilling,
            isUsed: this.bankAccount.map(bank => (bank.accountName)).includes(this.bankAccount[idx].accountNameForBilling)
          })
        }
      }
    },

    addBank() {
      this.bankAccount.push(JSON.parse(JSON.stringify({...this.bankSet})));
      this.addUniqueBillingAccount();
      this.checkIfCardIsComplete();
      this.$emit("handleCardHeight");
    },

    async deleteBank(detail, key) {
      const data = this.bankAccount[key];
      data.onboardingRequestId = this.$route.params.introducerRequestedId;
      await this.removeBank(data);

      this.bankAccount.splice(key, 1);
      this.forSettlements.splice(key, 1);
      this.payload = JSON.parse(JSON.stringify(this.bankAccount));

      setTimeout(async () => {
        await this.validateForm()
        this.checkIfCardIsComplete();
      }, 100);
    },

    changeMessage(data, removeString, addString) {
      return data ? data.replace(removeString, addString) : "";
    },

    updateCurrImg(input, key) {
      if(Array.isArray(input.target.files) && input.target.files.length > 0 && input.target.files[0]['type'] !== 'application/pdf' ) {
        this.errors.add({
          field: `file[${key}]`,
          msg: 'Invalid file type, Accept PDF only'
        });
        return false
      }

      let files = input.target.files[0];
      this.bankAccount[key].bankStatement = files;
      this.bankAccount[key].hasFileUploaded = true;
      this.checkIfCardIsComplete();

      if (!this.isFileValid(files) && !this.errors.has(`file[${key}]`)) {
        this.errors.add({
          field: `file[${key}]`,
          msg: `The bank statement size must be less than ${this.maxFileSize} MB`,
        });
      } else {
        this.errors.remove(`file[${key}]`);
      }

      this.bankAccount[key]["fileuploadError"] = null;
      this.handleChange(key,'bankStatement')
    },

    updateCurrImgForBilling(input, key) {
      if (Array.isArray(input.target.files) && input.target.files.length > 0 && input.target.files[0]['type'] !== 'application/pdf') {
        this.errors.add({
          field: `fileforbilling[${key}]`,
          msg: 'Invalid file type, Accept PDF only'
        });
        return false
      }

      let files = input.target.files[0];
      this.bankAccount[key].bankStatementForBilling = files;
      this.bankAccount[key].hasFileUploadedForBilling = true;
      this.checkIfCardIsComplete();

      if (!this.isFileValid(files) && !this.errors.has(`fileforbilling[${key}]`)) {
        this.errors.add({
          field: `fileforbilling[${key}]`,
          msg: `The bank statement size must be less than ${this.maxFileSize} MB`,
        });
      } else {
        this.errors.remove(`fileforbilling[${key}]`);
      }

      this.bankAccount[key]["fileuploadError"] = null;
      this.handleChange(key,'bankStatementForBilling')
    },

    checkIfCardIsComplete() {
      let statuses = [];
      let status = this.bankAccount && Array.isArray(this.bankAccount) ? this.bankAccount.length >= 1 : false;

      if (this.bankAccount && Array.isArray(this.bankAccount) && this.bankAccount.length > 0) {
        for (let index = 0; index < this.bankAccount.length; index++) {
          statuses[index] =
            this.bankAccount[index].bsb.length > 0 &&
            !this.errors.has(`bsb[${index}]`) &&
            "bsbForBilling" in this.bankAccount[index] && this.bankAccount[index].bsbForBilling.length > 0 &&
            !this.errors.has(`bsbforbilling[${index}]`) &&
            this.bankAccount[index].accountName.length > 0 &&
            this.bankAccount[index].accountNumber != "" &&
            !this.errors.has(`accno[${index}]`) &&
            "accountNameForBilling" in this.bankAccount[index] && this.bankAccount[index].accountNameForBilling.length > 0 &&
            this.bankAccount[index].accountNumberForBilling != "" &&
            !this.errors.has(`accnoforbilling[${index}]`) &&
            this.bankAccount[index].entity.length > 0 &&
            !this.errors.has(`entity[${index}]`) &&
            this.bankAccount[index].entityForBilling.length > 0 &&
            !this.errors.has(`entityforbilling[${index}]`) &&
            ((typeof this.bankAccount[index].bankStatement === "string" &&
                this.bankAccount[index].bankStatement.length > 0) ||
              this.bankAccount[index].hasFileUploaded === true) &&
            ( "bankStatementForBilling" in this.bankAccount[index] &&
              ((typeof this.bankAccount[index].bankStatementForBilling === "string"
                  && this.bankAccount[index].bankStatementForBilling.length > 0
              )
              || this.bankAccount[index].hasFileUploadedForBilling === true)
            );
        }

        for (let index = 0; index < statuses.length; index++) {
          if (!statuses[index]) {
            status = false;
            break;
          }
        }
      }

      this.hasBeenCompleted.bankAccountStatus = status;
      this.$emit("checkDirty", true);
    },

    validateForm() {
      let valid = true;
      this.bankAccount.forEach((account, index) => {
        if (!account.bankStatement) {
          valid = false;
          this.errors.add({
            field: `file[${index}]`,
            msg: "The Bank statement field is mandatory",
          });
        }

        if (typeof account.bankStatementForBilling === "string" && account.bankStatementForBilling.length === 0) {
          valid = false;
          this.errors.add({
            field: `fileforbilling[${index}]`,
            msg: "The Bank statement field is mandatory",
          });
        } else {
          this.errors.remove(`fileforbilling[${index}]`);
        }
      });

      if (!valid) {
        this.$validator.validateAll();
        return false;
      }

      return this.$validator.validateAll();
    },

    validateFile() {
      for (let index = 0; index < this.bankAccount.length; index++) {
        if (typeof this.bankAccount[index].bankStatement !== "string" || this.bankAccount[index].bankStatement == undefined) {
          this.bankAccount[index]["fileuploadError"] = "Bank statement is required";
          return false;
        }

        if (typeof this.bankAccount[index].bankStatementForBilling !== "string" || this.bankAccount[index].bankStatementForBilling == undefined) {
          this.bankAccount[index]["fileuploadError"] = "Bank statement is required";
          return false;
        }
      }
      return true;
    },

    resetBank(bank, index) {

      // what is going on here? I must be missing something because no one would do this without a very good reason - 2023-06-23
      this.bankAccount[index].accountName = bank.existingBankAccount ? "" : "";
      this.bankAccount[index].bsb = bank.existingBankAccount ? "" : "";
      this.bankAccount[index].accountNumber = bank.existingBankAccount ? "" : "";
      this.bankAccount[index].bankStatement = bank.existingBankAccount ? "" : "";
      this.bankAccount[index].hasFileUploaded = bank.existingBankAccount ? false : false;
      this.bankAccount[index].selectedSettlementBank = bank.existingBankAccount ? "" : "";
      this.bankAccount[index].entity = bank.existingBankAccount ? "" : "";

      if (!bank.existingBankAccount) {
        this.$refs.uploadImgInput[index].value = null;
      }
    },

    resetBillingBank(bank, index) {
      this.bankAccount[index].accountNameForBilling = bank.existingBankAccountForBilling ? this.bankAccount[index].accountNameForBilling : "";
      this.bankAccount[index].bsbForBilling = bank.existingBankAccountForBilling ? this.bankAccount[index].bsbForBilling : "";
      this.bankAccount[index].accountNumberForBilling = bank.existingBankAccountForBilling ? this.bankAccount[index].accountNumberForBilling : "";
      this.bankAccount[index].bankStatementForBilling = bank.existingBankAccountForBilling ? this.bankAccount[index].bankStatementForBilling : "";
      this.bankAccount[index].hasFileUploadedForBilling = bank.existingBankAccountForBilling ? this.bankAccount[index].hasFileUploadedForBilling : false;
      this.bankAccount[index].selectedBillingBank = bank.existingBankAccountForBilling ? this.bankAccount[index].selectedBillingBank : "";
      this.bankAccount[index].entityForBilling = bank.existingBankAccountForBilling ? this.bankAccount[index].entityForBilling : "";

      if ( !bank.existingBankAccountForBilling )
        this.$refs.uploadImgInputForBilling[index].value = null;
    },

    toggleAccountForSettlement(bank, index) {
      this.resetBank(bank, index);
      this.addUniqueBillingAccount();
    },

    getType(payload) {
      return typeof payload;
    },

    selectSettlementBank(bank, index) {
      bank.accountName = this.forSettlements.find(settlementBank => settlementBank.accountName === bank.selectedSettlementBank).accountName;
      bank.bsb = this.forSettlements.find(settlementBank => settlementBank.accountName === bank.selectedSettlementBank).bsb;
      bank.accountNumber = this.forSettlements.find(settlementBank => settlementBank.accountName === bank.selectedSettlementBank).accountNumber;
      bank.bankStatement = this.forSettlements.find(settlementBank => settlementBank.accountName === bank.selectedSettlementBank).bankStatement;
      bank.hasFileUploaded = this.getType(this.forSettlements.find(settlementBank => settlementBank.accountName === bank.selectedSettlementBank).bankStatement) !== 'string';
      bank.entity = this.forSettlements.find(settlementBank => settlementBank.accountName === bank.selectedSettlementBank).entity;

      const selectedAccountIndex = this.forSettlements.findIndex(row => row.accountName === bank.selectedSettlementBank);
      this.forSettlements[selectedAccountIndex].isUsed = true;
      this.forSettlements = [];

      setTimeout(async () => {
        await this.validateForm();
        if (
          typeof this.bankAccount[index].bankStatement === "object"
          || (typeof this.bankAccount[index].bankStatement === "string"
            && this.bankAccount[index].bankStatement.length > 0
          )
        ) this.errors.remove(`file[${index}]`);
        this.checkIfCardIsComplete();
      }, 100);
      this.addUniqueBillingAccount();
      this.handleChange(index,'existingBankAccount')
    },

    selectBillingBank(bank, index) {
      bank.accountNameForBilling = this.forBillings.find(billingBank => billingBank.accountName === bank.selectedBillingBank).accountName;
      bank.bsbForBilling = this.forBillings.find(billingBank => billingBank.accountName === bank.selectedBillingBank).bsb;
      bank.accountNumberForBilling = this.forBillings.find(billingBank => billingBank.accountName === bank.selectedBillingBank).accountNumber;
      bank.bankStatementForBilling = this.forBillings.find(billingBank => billingBank.accountName === bank.selectedBillingBank).bankStatement;
      bank.hasFileUploadedForBilling = this.getType(this.forBillings.find(billingBank => billingBank.accountName === bank.selectedBillingBank).bankStatement) !== 'string';
      bank.entityForBilling = this.forBillings.find(billingBank => billingBank.accountName === bank.selectedBillingBank).entity;

      const selectedAccountIndex = this.forBillings.findIndex(row => row.accountName === bank.selectedBillingBank);
      this.forBillings[selectedAccountIndex].isUsed = true;

      setTimeout(async () => {
        await this.validateForm();
        if (
          typeof this.bankAccount[index].bankStatementForBilling === "object"
          || (typeof this.bankAccount[index].bankStatementForBilling === "string"
            && this.bankAccount[index].bankStatementForBilling.length > 0
          )
        ) this.errors.remove(`fileForBilling[${index}]`);
        this.checkIfCardIsComplete();
      }, 100);

      this.handleChange(index,'existingBankAccountForBilling')
    },

    dataSelectedFromListing(index) {
      return this.bankAccount[index].accountName.length > 0
        && this.bankAccount[index].bsb.length > 0
        && this.bankAccount[index].accountNumber.toString().length > 0
        && this.bankAccount[index].entity.length > 0;
    },

    dataSelectedFromListingForBanking(index) {
      return this.bankAccount[index].accountNameForBilling.length > 0
        && this.bankAccount[index].bsbForBilling.length > 0
        && this.bankAccount[index].accountNumberForBilling.toString().length > 0
        && this.bankAccount[index].entityForBilling.length > 0;
    },

    toggleAccountForBilling(bank, index) {
      this.resetBillingBank(bank, index);
      this.addUniqueBillingAccount();

      if ( bank.existingBankAccountForBilling ) {
        if ( bank.sameAsSettlementAccount ) {
          bank.sameAsSettlementAccount = false;
          this.handleBankAsSettlementToggle(bank, index);
        }
      }
    },

    handleBankAsSettlementToggle(bank, index) {
      this.bankAccount[index].accountNameForBilling = bank.sameAsSettlementAccount ? this.bankAccount[index].accountName : "";
      this.bankAccount[index].bsbForBilling = bank.sameAsSettlementAccount ? this.bankAccount[index].bsb : "";
      this.bankAccount[index].accountNumberForBilling = bank.sameAsSettlementAccount ? this.bankAccount[index].accountNumber : "";
      this.bankAccount[index].bankStatementForBilling = bank.sameAsSettlementAccount ? this.bankAccount[index].bankStatement : "";
      this.bankAccount[index].hasFileUploadedForBilling = bank.sameAsSettlementAccount ? this.bankAccount[index].hasFileUploaded : false;
      this.bankAccount[index].entityForBilling = bank.sameAsSettlementAccount ? this.bankAccount[index].entity : "";

      if (!bank.sameAsSettlementAccount) {
        this.$refs.uploadImgInputForBilling[index].value = null;
      }

      if ( bank.sameAsSettlementAccount ) {
        if ( bank.existingBankAccountForBilling ) {
          this.resetBillingBank(bank, index);
          bank.selectedBillingBank = "";
          bank.existingBankAccountForBilling = false;
        }
      }

      setTimeout(async () => {
        await this.validateForm();
        if (
          typeof this.bankAccount[index].bankStatementForBilling === "object"
          || (typeof this.bankAccount[index].bankStatementForBilling === "string" && this.bankAccount[index].bankStatementForBilling.length > 0)
        ) this.errors.remove(`fileforbilling[${index}]`);
        this.checkIfCardIsComplete();
      }, 100);
      this.handleChange(index, 'sameAsSettlementAccount')
    },

    readableName(fileName) {
      const newName = fileName.split("/").pop();
      return newName.split("-").splice(1, newName.split('-').length).join("-");
    },

    hasUnUsedBankingAccount(index) {
      if (index === 0) return false
      if (this.forSettlements.length === 0) return false;
      else return true;
    },

    hasUnUsedBankingAccountForBilling(index) {
      if (index === 0) {
        return false;
      }

      if (this.forBillings.length === 0) return false;
      else return true;
    },

    getValidBanks(account) {
      if (this.forSettlements.length === 0) return [];

      return this.forSettlements.filter(bank => {
        if ( "selectedSettlementBank" in account ) {
          return ((account.selectedSettlementBank === bank.accountName)
            || ("isUsed" in bank && !bank.isUsed));
        }
        else
          return "isUsed" in bank && !bank.isUsed
      });
    },

    getValidBillingBanks() {
      if (this.forBillings.length === 0) return [];

      return this.forBillings;
    },

    getEntity(id){
      if(!id) return "";
      let name = "";
      let objIndex = this.entityList.findIndex(
        (obj) => obj.entityId == id
      );
      if(objIndex >= 0){
        name = this.entityList[objIndex].entityType == 'Company' ? this.entityList[objIndex].companyName : this.entityList[objIndex].fullName;
      }
      return name;
    },
    handleChange(index,flag){
      try {
        if(this.applicationStatus === 'Info Requested'){
          let changedlabel = `bankAccount[${index}]${flag}`
          const data = {
            label: changedlabel,
            oldValue: this.payload[index] ? this.payload[index][flag] : "",
            newValue: typeof this.bankAccount[index][flag] == "object" ? this.bankAccount[index][flag].name : this.bankAccount[index][flag]
          }
          this.$emit("resubmit", data);
        }
        this.checkIfCardIsComplete();
      } catch(ex) {
        sentryLog(ex, { message: "Error occure on bank account handleChange function" });
      }
    },
    isFileValid(file) {
      return file.size <= Number(this.maxFileSize * 1024 * 1024);
    },
  },

  mounted() {
    this.bankAccount.statement = this.files;
    this.checkIfCardIsComplete();
    if (this.bankAccount.length===0) this.bankAccount.push({...this.bankSet});
    this.payload = JSON.parse(JSON.stringify(this.bankAccount));
    this.addUniqueBillingAccount();
  },

  created() {
    this.hasBeenCompleted = this.isComplete;
    this.bankAccount.map((item) => ({
      ...item,
      validationMessage: "",
    }));

    this.onboardingRequestId = this.$route.params.introducerRequestedId;

    EventBus.$on("addEntityList", async ({entity,newdata}) => {
      if (this.entityList.length == 1) {
        const newBank = this.bankAccount.map((bank) => {
          bank.entity = entity.entityId;
          bank.entityForBilling = entity.entityId;
          return bank;
        });
        this.bankAccount = [...newBank]
      } else if (this.entityList.length == 2) {
        if(newdata){
          const newBank = this.bankAccount.map((bank) => {
            bank.entity = bank.entity ? bank.entity : null;
            bank.entityForBilling = bank.entityForBilling ? bank.entityForBilling : null;
            return bank;
          });
          this.bankAccount = [...newBank]
        }
      }
      this.checkIfCardIsComplete();
    });

    EventBus.$on("checkEntityList", async (entityList) => {
      if (entityList.length == 1) {
        const newBank = this.bankAccount.map((bank) => {
          bank.entity = entityList[0].entityId;
          bank.entityForBilling = entityList[0].entityId;
          return bank;
        });
        this.bankAccount = [...newBank]
      }
      this.checkIfCardIsComplete();
    });
  },

  computed: {
    baseUrl() {
      return process.env.VUE_APP_API_URL;
    },
  },

  watch: {
    isFormDirty(val) {
      if (val) {
        this.$emit("checkDirty", this.isFormDirty);
      }
    },
  }
};
</script>
