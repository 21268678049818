export default [
  {
    industry: "Electricity, gas, water and waste services",
    name: "Electricity distribution"
  },
  {
    industry: "Electricity, gas, water and waste services",
    name: "Electricity transmission"
  },
  {
    industry: "Electricity, gas, water and waste services",
    name: "Fossil fuel electricity generation"
  },
  {
    industry: "Electricity, gas, water and waste services",
    name: "Gas supply"
  },
  {
    industry: "Electricity, gas, water and waste services",
    name: "Hydro-electricity generation"
  },
  {
    industry: "Electricity, gas, water and waste services",
    name: "On-selling electricity and electricity market operation"
  },
  {
    industry: "Electricity, gas, water and waste services",
    name: "Other electricity generation"
  },
  {
    industry: "Electricity, gas, water and waste services",
    name: "Other waste collection services"
  },
  {
    industry: "Electricity, gas, water and waste services",
    name: "Sewerage and drainage services"
  },
  {
    industry: "Electricity, gas, water and waste services",
    name: "Solid waste collection services"
  },
  {
    industry: "Electricity, gas, water and waste services",
    name: "Waste remediation and materials recovery services"
  },
  {
    industry: "Electricity, gas, water and waste services",
    name: "Waste treatment and disposal services"
  },
  {
    industry: "Electricity, gas, water and waste services",
    name: "Water supply"
  },
]
