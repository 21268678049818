export default [
  {
    industry: "Manufacturing",
    name: "Adhesive mfg"
  },
  {
    industry: "Manufacturing",
    name: "Agricultural machinery and equipment mfg"
  },
  {
    industry: "Manufacturing",
    name: "Aircraft mfg and repair services"
  },
  {
    industry: "Manufacturing",
    name: "Alumina production"
  },
  {
    industry: "Manufacturing",
    name: "Aluminium rolling, drawing, extruding"
  },
  {
    industry: "Manufacturing",
    name: "Aluminium smelting"
  },
  {
    industry: "Manufacturing",
    name: "Architectural aluminium product mfg"
  },
  {
    industry: "Manufacturing",
    name: "Automotive electrical component mfg"
  },
  {
    industry: "Manufacturing",
    name: "Bakery product mfg (non-factory based)"
  },
  {
    industry: "Manufacturing",
    name: "Basic inorganic chemical mfg"
  },
  {
    industry: "Manufacturing",
    name: "Basic organic chemical mfg"
  },
  {
    industry: "Manufacturing",
    name: "Beer mfg"
  },
  {
    industry: "Manufacturing",
    name: "Biscuit mfg (factory based)"
  },
  {
    industry: "Manufacturing",
    name: "Boatbuilding and repair services"
  },
  {
    industry: "Manufacturing",
    name: "Boiler, tank and other heavy gauge metal container mfg"
  },
  {
    industry: "Manufacturing",
    name: "Bread mfg (factory based)"
  },
  {
    industry: "Manufacturing",
    name: "Cake and pastry mfg (factory based)"
  },
  {
    industry: "Manufacturing",
    name: "Cement and lime mfg"
  },
  {
    industry: "Manufacturing",
    name: "Cereal, pasta and baking mix mfg"
  },
  {
    industry: "Manufacturing",
    name: "Cheese and other dairy product mfg"
  },
  {
    industry: "Manufacturing",
    name: "Cigarette and tobacco product mfg"
  },
  {
    industry: "Manufacturing",
    name: "Clay brick mfg"
  },
  {
    industry: "Manufacturing",
    name: "Cleaning compound mfg"
  },
  {
    industry: "Manufacturing",
    name: "Clothing mfg"
  },
  {
    industry: "Manufacturing",
    name: "Communication equipment mfg"
  },
  {
    industry: "Manufacturing",
    name: "Computer and electronic office equipment mfg"
  },
  {
    industry: "Manufacturing",
    name: "Concrete product mfg"
  },
  {
    industry: "Manufacturing",
    name: "Confectionary mfg"
  },
  {
    industry: "Manufacturing",
    name: "Copper, silver, lead and zinc smelting and refining"
  },
  {
    industry: "Manufacturing",
    name: "Corrugated paperboard and paperboard container mfg"
  },
  {
    industry: "Manufacturing",
    name: "Cosmetic and toiletry preparation mfg"
  },
  {
    industry: "Manufacturing",
    name: "Cured meat and smallgoods mfg"
  },
  {
    industry: "Manufacturing",
    name: "Cut and sewn textile product mfg"
  },
  {
    industry: "Manufacturing",
    name: "Electric cable and wire mfg"
  },
  {
    industry: "Manufacturing",
    name: "Electric lighting equipment mfg"
  },
  {
    industry: "Manufacturing",
    name: "Explosive mfg"
  },
  {
    industry: "Manufacturing",
    name: "Fertiliser mfg"
  },
  {
    industry: "Manufacturing",
    name: "Fixed space heating, cooling, ventilation equipment mfg"
  },
  {
    industry: "Manufacturing",
    name: "Footwear mfg"
  },
  {
    industry: "Manufacturing",
    name: "Fruit and vegetable processing"
  },
  {
    industry: "Manufacturing",
    name: "Glass and glass product mfg"
  },
  {
    industry: "Manufacturing",
    name: "Grain mill product mfg"
  },
  {
    industry: "Manufacturing",
    name: "Human pharmaceutical and medicinal product mfg"
  },
  {
    industry: "Manufacturing",
    name: "Ice cream mfg"
  },
  {
    industry: "Manufacturing",
    name: "Industrial gas mfg"
  },
  {
    industry: "Manufacturing",
    name: "Iron and steel casting"
  },
  {
    industry: "Manufacturing",
    name: "Iron and steel forging"
  },
  {
    industry: "Manufacturing",
    name: "Iron smelting and steel mfg"
  },
  {
    industry: "Manufacturing",
    name: "Jewellery and silverware mfg"
  },
  {
    industry: "Manufacturing",
    name: "Knitted product mfg"
  },
  {
    industry: "Manufacturing",
    name: "Leather tanning, fur dressing and leather product mfg"
  },
  {
    industry: "Manufacturing",
    name: "Lifting and material handling equipment mfg"
  },
  {
    industry: "Manufacturing",
    name: "Log sawmilling"
  },
  {
    industry: "Manufacturing",
    name: "Machine tool and parts mfg"
  },
  {
    industry: "Manufacturing",
    name: "Mattress mfg"
  },
  {
    industry: "Manufacturing",
    name: "Meat processing"
  },
  {
    industry: "Manufacturing",
    name: "Medical and surgical equipment mfg"
  },
  {
    industry: "Manufacturing",
    name: "Metal furniture mfg"
  },
  {
    industry: "Manufacturing",
    name: "Metal roof and guttering mfg (except aluminium)"
  },
  {
    industry: "Manufacturing",
    name: "Metal, coating and finishing"
  },
  {
    industry: "Manufacturing",
    name: "Milk and cream processing"
  },
  {
    industry: "Manufacturing",
    name: "Mining and construction machinery mfg"
  },
  {
    industry: "Manufacturing",
    name: "Motor vehicle body and trailer mfg"
  },
  {
    industry: "Manufacturing",
    name: "Motor vehicle mfg"
  },
  {
    industry: "Manufacturing",
    name: "Natural rubber product mfg"
  },
  {
    industry: "Manufacturing",
    name: "Natural textile mfg"
  },
  {
    industry: "Manufacturing",
    name: "Non-ferrous metal casting"
  },
  {
    industry: "Manufacturing",
    name: "Nut, bolt, screw and rivet mfg"
  },
  {
    industry: "Manufacturing",
    name: "Oil and fat mfg"
  },
  {
    industry: "Manufacturing",
    name: "other basic chemical product mfg nec"
  },
  {
    industry: "Manufacturing",
    name: "Other basic non-ferrous metal mfg"
  },
  {
    industry: "Manufacturing",
    name: "Other basic non-ferrous metal product mfg"
  },
  {
    industry: "Manufacturing",
    name: "Other basic polymer mfg"
  },
  {
    industry: "Manufacturing",
    name: "Other ceramic product mfg"
  },
  {
    industry: "Manufacturing",
    name: "Other converted paper product mfg"
  },
  {
    industry: "Manufacturing",
    name: "Other domestic appliance mfg"
  },
  {
    industry: "Manufacturing",
    name: "Other electrical equipment mfg"
  },
  {
    industry: "Manufacturing",
    name: "Other electronic equipment mfg"
  },
  {
    industry: "Manufacturing",
    name: "Other fabricated metal product mfg nec"
  },
  {
    industry: "Manufacturing",
    name: "Other food product manufacturing nec"
  },
  {
    industry: "Manufacturing",
    name: "Other furniture mfg"
  },
  {
    industry: "Manufacturing",
    name: "Other machinery and equipment mfg nec"
  },
  {
    industry: "Manufacturing",
    name: "Other metal container mfg"
  },
  {
    industry: "Manufacturing",
    name: "Other mfg nec"
  },
  {
    industry: "Manufacturing",
    name: "Other motor vehicle parts mfg"
  },
  {
    industry: "Manufacturing",
    name: "Other non-metallic mineral product mfg"
  },
  {
    industry: "Manufacturing",
    name: "Other petroleum and coal product mfg"
  },
  {
    industry: "Manufacturing",
    name: "Other polymer product mfg"
  },
  {
    industry: "Manufacturing",
    name: "Other professional and scientific equipment mfg"
  },
  {
    industry: "Manufacturing",
    name: "Other specialised machinery and equipment mfg"
  },
  {
    industry: "Manufacturing",
    name: "Other structural metal product mfg"
  },
  {
    industry: "Manufacturing",
    name: "Other transport equipment mfg nec"
  },
  {
    industry: "Manufacturing",
    name: "Other wood product mfg nec"
  },
  {
    industry: "Manufacturing",
    name: "Paint and coatings mfg"
  },
  {
    industry: "Manufacturing",
    name: "Paper bag mfg"
  },
  {
    industry: "Manufacturing",
    name: "Paper stationery mfg"
  },
  {
    industry: "Manufacturing",
    name: "Pesticide mfg"
  },
  {
    industry: "Manufacturing",
    name: "Petroleum refining and petroleum fuel mfg"
  },
  {
    industry: "Manufacturing",
    name: "Photographic chemical product mfg"
  },
  {
    industry: "Manufacturing",
    name: "Photographic, optical and opthalmic equipment mfg"
  },
  {
    industry: "Manufacturing",
    name: "Plaster product mfg"
  },
  {
    industry: "Manufacturing",
    name: "Polymer film and sheet packaging material mfg"
  },
  {
    industry: "Manufacturing",
    name: "Polymer foam product mfg"
  },
  {
    industry: "Manufacturing",
    name: "Potato, corn and other crisp mfg"
  },
  {
    industry: "Manufacturing",
    name: "Poultry processing"
  },
  {
    industry: "Manufacturing",
    name: "Prefabricated metal building mfg"
  },
  {
    industry: "Manufacturing",
    name: "Prefabricated wooden building mfg"
  },
  {
    industry: "Manufacturing",
    name: "Prepared animal and bird feed manufacturing"
  },
  {
    industry: "Manufacturing",
    name: "Printing"
  },
  {
    industry: "Manufacturing",
    name: "Printing support services"
  },
  {
    industry: "Manufacturing",
    name: "Pulp, paper and paperboard mfg"
  },
  {
    industry: "Manufacturing",
    name: "Pump and compressor mfg"
  },
  {
    industry: "Manufacturing",
    name: "Railway rolling stock mfg and repair services"
  },
  {
    industry: "Manufacturing",
    name: "Ready-mixed concrete mfg"
  },
  {
    industry: "Manufacturing",
    name: "Reconstituted wood product mfg"
  },
  {
    industry: "Manufacturing",
    name: "Reproduction of recorded media"
  },
  {
    industry: "Manufacturing",
    name: "Rigid and semi-rigid polymer product mfg"
  },
  {
    industry: "Manufacturing",
    name: "Rope, cordage and twine mfg"
  },
  {
    industry: "Manufacturing",
    name: "Sanitary paper product mfg"
  },
  {
    industry: "Manufacturing",
    name: "Seafood processing"
  },
  {
    industry: "Manufacturing",
    name: "Sheet metal product mfg (except metal structural and container products)"
  },
  {
    industry: "Manufacturing",
    name: "Shipbuilding and repair services"
  },
  {
    industry: "Manufacturing",
    name: "Soft drink, cordial and syrup mfg"
  },
  {
    industry: "Manufacturing",
    name: "Spirit mfg"
  },
  {
    industry: "Manufacturing",
    name: "Spring and wire product mfg"
  },
  {
    industry: "Manufacturing",
    name: "Steel pipe and tube mfg"
  },
  {
    industry: "Manufacturing",
    name: "Structural steel fabricating"
  },
  {
    industry: "Manufacturing",
    name: "Sugar mfg"
  },
  {
    industry: "Manufacturing",
    name: "Synthetic resin and synthetic rubber mfg"
  },
  {
    industry: "Manufacturing",
    name: "Synthetic textile mfg"
  },
  {
    industry: "Manufacturing",
    name: "Textile finishing and other textile product mfg"
  },
  {
    industry: "Manufacturing",
    name: "Textile floor covering mfg"
  },
  {
    industry: "Manufacturing",
    name: "Timber resawing and dressing"
  },
  {
    industry: "Manufacturing",
    name: "Toy, sporting and recreational product mfg"
  },
  {
    industry: "Manufacturing",
    name: "Tyre mfg"
  },
  {
    industry: "Manufacturing",
    name: "Veneer and plywood mfg"
  },
  {
    industry: "Manufacturing",
    name: "Veterinary pharmaceutical and medicinal product mfg"
  },
  {
    industry: "Manufacturing",
    name: "Whiteware appliance mfg"
  },
  {
    industry: "Manufacturing",
    name: "Wine and other alcoholic beverage mfg"
  },
  {
    industry: "Manufacturing",
    name: "Wood chipping"
  },
  {
    industry: "Manufacturing",
    name: "Wooden furniture and upholstered seat mfg"
  },
  {
    industry: "Manufacturing",
    name: "Wooden structural fitting and component mfg"
  },
  {
    industry: "Manufacturing",
    name: "Wool scouring"
  },
]
